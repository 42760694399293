import React, { useState } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import {useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChickenBiryani from '../../assets/chickenBiryani.png';
import FriedRice from '../../assets/friedRice.png';
import SearchBar from "../../components/search_bar/search_bar_main";
import SocialMediaBar from "../../components/social_media_bar/social_media_bar_main";
import SubscriptionPackages from "./subscriptionPackages.page";

const SubscriptionPage = () => {
  const [showPackages, setShowPackages] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  

  const handleFormSubmit = () => {
    if (isAuthenticated) {
      setShowPackages(true);
    } else {
      setShowPackages(false);
      toast.error("Please login to be a registered member.")
      navigate('/login');
      return
    }
    // setOpen(false);
  };

  const handleCardButtonClick = (frequency) => {
    navigate(`/availableplans/${frequency.toLowerCase()}`);
  };

  return (
    <>
      <SearchBar />
      <SocialMediaBar />
      {!showPackages && (
        <Grid container justifyContent="center" sx={{ marginTop: "7rem" }}>
          <Grid item xs={12} sm={10} md={8}>
            <Typography
              variant="h5"
              className="responsive_fontsize24"
              sx={{ fontWeight: "bold", textAlign: "center", mt: "2rem", mb: "2rem", color: 'black' }}
            >
              "Say goodbye to meal planning stress and hello to subscription bliss - where good food is just a click away."
            </Typography> 
            <Card
              sx={{
                width: "100%",
                maxWidth: "1200px",
                backgroundColor: "#261d1d",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                margin: "auto",
                borderRadius: "8px",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className="responsive_fontsize20" variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
                      <span style={{ backgroundColor: '#0ff34f', padding: '0.2rem' }}>
                        Be a BBG Member
                      </span>
                    </Typography>
                    <Typography className="responsive_fontsize16" variant="body2" sx={{ mb: 2, color: 'white' }}>
                      Crenn collection for one member $3,800
                      <br />
                      Crenn collection for two member $3,800
                    </Typography>
                    <Typography className="responsive_fontsize16" variant="body2" sx={{ mb: 2, color: 'white' }}>
                      • Access to a full calendar year of Atelier Crenn responsive, available with 72 hours notice
                      <br />
                      • An open Table each night at our new cocktail lounge, Bar Crenn, available with 72 hours notice
                      <br />
                      • Priority access to all Crenn events, Chef Series, Pop-ups
                      <br />
                      • Four member-exclusive events throughout the year such as private winery tour, picnic at Atelier Crenn's Bleu Belle Farm, spirits tasting at Bar Crenn, and a winemaker dinner
                      <br />
                      • A seasonal gift box for members
                      <br />
                      • A dedicated Crenn Concierge service for any Crenn requests, and professional advice from Crenn leaders on wine, food and cocktail
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", marginTop: { md: "125px", lg: "105px" } }}>
                        <Grid item xs={12} md={7}>
                          <img src={ChickenBiryani} alt="Chicken Biryani" style={{ height: "auto", width: "100%", objectFit: "cover" }} />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <img src={FriedRice} alt="Fried Rice" style={{ width: '100%', height: 'auto', objectFit: "cover" }} />
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  className="responsive_fontsize18 button"
                  variant="contained"
                  color="primary"
                  sx={{ color: 'white', backgroundColor: 'orange' }}
                  onClick={handleFormSubmit}
                >
                  Become a Member
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      )}

      {showPackages && <SubscriptionPackages handleCardButtonClick={handleCardButtonClick} />}
    </>
  );
};

export default SubscriptionPage;

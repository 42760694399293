import React, { useState } from "react";
import { Box, Button, Avatar, Typography } from "@mui/material";
import packageIcon from "../../assets/packageIcon.png";
import cookingIcon from "../../assets/cookingIcon.png";
import deliverIcon from "../../assets/deliverIcon.png";
import deliveredIcon from "../../assets/deliveredIcon.png";

function TrackSectionOrderDetails({ orders }) {
  const [trackingId, setTrackingId] = useState("");
  const [trackStatus, setTrackStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  // Function to handle the tracking ID submission
  const handleTrackOrder = () => {
    const order = orders.find((order) => order.tracking_id === trackingId);

    if (order) {
      setTrackStatus(order.track_status); // Set the track status from the found order
      setErrorMessage(""); // Clear any previous error message
    } else {
      setTrackStatus(null); // Reset track status if no match is found
      setErrorMessage("Tracking ID doesn't match any order.");
    }
  };

  // Function to get the icon styles based on track status
  const getIconStyles = (status) => ({
    width: { xs: 40, sm: 54 },
    height: { xs: 40, sm: 54 },
    boxShadow: trackStatus === status ? "0px 0px 10px 2px #000" : "none", // Highlight effect
    border: trackStatus === status ? "2px solid #000" : "none",
  });

  return (
    <>
      <form style={{ position: "relative" }}>
        <input
          type="text"
          placeholder="Enter Tracking Id: "
          className="track__order--input"
          value={trackingId}
          onChange={(e) => setTrackingId(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
            backgroundColor: "#000",
            fontSize: { xs: "0.85rem", sm: "1.25rem" },
            textTransform: "capitalize",
            borderRadius: "1.25rem",
            px: { xs: "0.95rem", sm: "1.2rem" },
            color: "#fff",
            height: "100%",
          }}
          onClick={handleTrackOrder}
        >
          Track Order
        </Button>
      </form>

      {errorMessage && (
        <Typography color="error" variant="body1" sx={{ marginTop: "1rem" }}>
          {errorMessage}
        </Typography>
      )}

      {trackStatus && (
        <Typography
          variant="h6"
          sx={{ marginTop: "1rem", fontWeight: "bold", textAlign: "center" }}
        >
          Track Status: {trackStatus}
        </Typography>
      )}

      <Box
        sx={{
          position: "relative",
          mt: "1.5rem",
          height: { xs: "2.5rem", sm: "3.5rem" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "0.1875rem",
            background: "#D9D9D9",
          }}
        ></Box>

        <Box sx={{ position: "absolute", top: "0", left: "0" }}>
          <Avatar
            alt="Cooking"
            src={cookingIcon}
            sx={getIconStyles("Cooking")}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "25%",
            transform: "translateX(50%)",
          }}
        >
          <Avatar
            alt="Packing"
            src={packageIcon}
            sx={getIconStyles("Packing")}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "25%",
            transform: "translateX(-50%)",
          }}
        >
          <Avatar
            alt="On the way"
            src={deliverIcon}
            sx={getIconStyles("On the way")}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
          }}
        >
          <Avatar
            alt="Delivered"
            src={deliveredIcon}
            sx={getIconStyles("Delivered")}
          />
        </Box>
      </Box>
    </>
  );
}

export default TrackSectionOrderDetails;

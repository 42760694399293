import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions,
    Button,
  } from "@mui/material";

function DeleteItemPopover({handleOpenDialog, handleItemDelete, handleClose, deleteItem, deleteMenuName}) {
  return (
    <Dialog open={handleOpenDialog} onClose={handleClose}>
      <DialogTitle>Delete Menu</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the item form "{deleteItem}" from "{deleteMenuName}"?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose }>Cancel</Button>
        <Button onClick={handleItemDelete} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteItemPopover

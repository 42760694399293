import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";


const VerifyOtp = ({ phoneNumber }) => {
  const [otp, setOtp] = useState("");
  const [generatedOtp, setGeneratedOtp] = useState();
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmail] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  

  const handleSendOtp = async () => {
    setLoading(true);
    try {
      const otpValue = Math.floor(100000 + Math.random() * 900000);
      setGeneratedOtp(otpValue);

      await axios.post(`https://www.bbgrestaurant.com/bbgapi/users/save-otp`, {
        customer_contact: phoneNumber,
        otp: otpValue,
        email,
      });

      toast.success("OTP sent to your email successfully.");
      setOtpSent(true);
    } catch (err) {
      toast.error(err.response?.data?.message || "Failed to send OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `https://www.bbgrestaurant.com/bbgapi/users/verify-otp`,
        {
          customer_contact: phoneNumber,
          otp: generatedOtp,
        }
      );

      if (response.data.success) {
        toast.success(response.data.message || "OTP verified successfully!");
        setDialogOpen(true);
      } else {
        throw new Error(response.data.message || "Unexpected error occurred.");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "Failed to verify OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `https://www.bbgrestaurant.com/bbgapi/users/reset-password`,
        {
          customer_contact: phoneNumber,
          new_password: newPassword,
        }
      );

      if (response.data.success) {
        toast.success(response.data.message || "Password reset successfully!");
        setDialogOpen(false);
       
      } 
    } catch (err) {
      toast.error(err.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!otpSent ? (
        <>
          <TextField
            label="Enter Email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            margin="normal"
          />
          <Button
            variant="contained"
            fullWidth
            onClick={handleSendOtp}
            disabled={loading || !email}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : "Send OTP"}
          </Button>
        </>
      ) : (
        <>
          <TextField
            label="Enter OTP"
            fullWidth
            variant="outlined"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            disabled={loading}
            margin="normal"
          />
          <Button
            variant="contained"
            fullWidth
            onClick={handleVerifyOtp}
            disabled={loading || !otp}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : "Verify OTP"}
          </Button>
        </>
      )}

      {/* Password Reset Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <TextField
            label="New Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            variant="outlined"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} disabled={loading}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleResetPassword}
            disabled={loading || !newPassword || !confirmPassword}
          >
            {loading ? <CircularProgress size={24} /> : "Reset Password"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VerifyOtp;

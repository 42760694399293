import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import VerifyOtp from "./verifyOtp";


const OtpDialog = ({ open, onClose, phoneNumber }) => {
  const [setPasswordDialogOpen] = useState(false);

  const handleOtpVerified = () => {
    onClose(); // Close the OTP dialog.
    setPasswordDialogOpen(true); // Open the password change dialog.
  };

 
  return (
    <>
      {/* OTP Verification Dialog */}
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>
          <Typography variant="h5" sx={{ color: "green" }}>
            Verify OTP
          </Typography>
        </DialogTitle>
        <DialogContent>
          <VerifyOtp phoneNumber={phoneNumber} onVerified={handleOtpVerified} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      
    </>
  );
};

export default OtpDialog;

import React from "react";
import { Box, Avatar, Typography, IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";

function ProfileHeader({ profilePicture, userName, fileInputRef, onProfilePictureChange }) {
  const MAX_FILE_SIZE_MB = 2; // Maximum file size in MB
  const handleProfilePictureChange = (event) => {
    try {
      const file = event.target.files[0];
      if (!file) {
        alert("No file selected. Please choose a valid image.");
        return;
      }

      // Validate file size
      const fileSizeInMB = file.size / (1024 * 1024); // Convert file size to MB
      if (fileSizeInMB > MAX_FILE_SIZE_MB) {
        alert(`File size exceeds the limit of ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`);
        return;
      }

      // Pass the selected file to the parent function
      onProfilePictureChange(event);
    } catch (error) {
      console.error("Error updating profile picture:", error);
      alert("An error occurred while updating your profile picture. Please try again.");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
      <Box sx={{ position: "relative", display: "inline-block" }}>
        <Avatar alt="Profile Picture" src={profilePicture} sx={{ width: 120, height: 120, marginBottom: 2 }} />
        <input
          ref={fileInputRef}
          accept="image/*"
          style={{ display: "none" }}
          id="icon-button-file"
          type="file"
          onChange={handleProfilePictureChange}
        />
        <label htmlFor="icon-button-file">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            sx={{
              position: "absolute",
              bottom: 4,
              right: 4,
              backgroundColor: "white",
              borderRadius: "50%",
              p: 0.5,
              boxShadow: 1,
            }}
          >
            <PhotoCamera />
          </IconButton>
        </label>
      </Box>
      <Typography
        className="responsive_fontsize25"
        variant="h5"
        sx={{ fontWeight: "600", marginBottom: 1.2 }}
      >
        {userName}
      </Typography>
    </Box>
  );
}

export default ProfileHeader;

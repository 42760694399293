import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";
import {
  Star as StarIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import SearchBar from "../search_bar/search_bar_main";
import SocialMediaBar from "../social_media_bar/social_media_bar_main";
import FoodCard from "./desFoodCard";
import feedbackData from "./feedbackData";
import IncrementDecrement from "../../views/menu/components/makeMenuItems/IncrementDecrement";
import IngredientsAndFeedback from "./ingredientsAndFeedback";
import CustomButton from "./customButton";
import {
  addItem,
  addToCheckout,
  moveCheckoutToCart,
  addToCart,
} from "src/redux/slices/cartSlice";
import { setSelectedFoodItem } from "./store/actions";
import { getSelectedFoodItem } from "./store/selectors";
import RelatedCardSlider from "./relatedCardSlider"; // Import the RelatedCardSlider

function Description() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const foodItem = useSelector(getSelectedFoodItem);
  console.log("FoodItem:", foodItem);

  const getCurrentPrice = () => {
    if (!foodItem) {
      console.error("foodItem is undefined or null");
      return 0; // Default price
    }
  
    const hour = new Date().getHours();
    const isDay = hour >= 6 && hour < 18;
  
    let prices = [];
    try {
      if (foodItem.item_price) {
        prices = JSON.parse(foodItem.item_price);
      } else {
        console.warn("item_price is missing or null");
        return 0; // Default price if item_price is missing
      }
    } catch (e) {
      console.error("Error parsing item_price:", e);
      return 0; // Return default price on parsing error
    }
  
    if (prices.length > 1) {
      return isDay ? prices[0] : prices[1];
    } else if (prices.length === 1) {
      return prices[0];
    } else {
      console.warn("Prices array is empty");
      return 0; // Return default price if no prices are available
    }
  };
  

  const price = getCurrentPrice();
  const [quantity, setQuantity] = useState(1);
  const [itemIngredients, setItemIngredients] = useState([]);

  useEffect(() => {
    console.log("location.state.foodItem:", location.state?.foodItem);
    if (location.state?.foodItem) {
      dispatch(setSelectedFoodItem(location.state.foodItem));
    }
  }, [location.state, dispatch]);

    useEffect(() => {
      console.log("item_ingredients:", foodItem?.item_ingredients);
      if (foodItem?.item_ingredients) {
        try {
          const ingredients = JSON.parse(foodItem.item_ingredients);
          setItemIngredients(ingredients);
        } catch (error) {
          console.error("Error parsing item_ingredients:", error);
          setItemIngredients([]);
        }
      } else {
        console.log("Item ingredients is missing or null.")
        setItemIngredients([]);
      }
    }, [foodItem]);

  const handleAddToCart = () => {
    const itemToAdd = {
      ...foodItem,
      quantity,
      subcategory: location.state?.subcategory || "",
      price: price,
      detail: foodItem.item_description,
    };
    dispatch(addItem(itemToAdd));
    toast.success(`Added ${itemToAdd.subcategory} to your cart`);
  };

  const handleAddToCheckout = () => {
    const concatenatedSubcategory =
      `${foodItem.item_subcategory} ${foodItem.item_type} ${foodItem.item_name}`.trim();

    const itemToAdd = {
      ...foodItem,
      quantity,
      subcategory: concatenatedSubcategory,
      price: price,
      detail: foodItem.item_description,
    };

    dispatch(addToCheckout(itemToAdd));
    dispatch(addToCart(itemToAdd));
    toast.success(`${concatenatedSubcategory} added to checkout and cart!`);
    navigate("/checkout");
  };

  useEffect(() => {
    if (location.pathname !== "/checkout") {
      dispatch(moveCheckoutToCart());
    }
  }, [location.pathname, dispatch]);

  if (!foodItem || !foodItem.image) {
    return <Box>Loading... or No image available</Box>;
  }

  const stars = Array.from({ length: 3 });

  return (
    <Box>
      <SearchBar />
      <SocialMediaBar />
      <Box sx={{ backgroundColor: "#f5f5f5", py: 3, px: { xs: 2, md: 4 } }}>
        <Grid
          container
          spacing={3}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          {/* Left Grid Item (Image) */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
              alignItems: "center",
            }}
          >
            <FoodCard foodItem={foodItem} />
          </Grid>

          {/* Right Grid Item (Details) */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              textAlign: { xs: "center", md: "left" }, // Center on small screens, left-align on large
              px: 2,
            }}
          >
            <Typography
              className="responsive_fontsize48"
              sx={{ color: "#166F2A", fontWeight: 500 }}
            >
              {location.state?.subcategory}
            </Typography>

            <Typography className="responsive_fontsize18" variant="body1">
              {foodItem.item_description}
            </Typography>

            <Box sx={{ mt: 2 }}>
              <Box sx={{ mb: 2 }}>
                <IncrementDecrement
                  count={quantity}
                  onIncrement={() => setQuantity(quantity + 1)}
                  onDecrement={() => setQuantity(Math.max(1, quantity - 1))}
                />
              </Box>
              <Typography sx={{ mb: 1 }}>Price: Rs {price}</Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                <CustomButton text="Add to Cart" onClick={handleAddToCart} />
                <CustomButton text="Order Now" onClick={handleAddToCheckout} />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <IngredientsAndFeedback
          stars={stars}
          itemIngredients={itemIngredients}
        />
        
        {/* Pass both subcategory and item_name to filter related items */}
        <RelatedCardSlider 
          currentSubcategory={foodItem.item_subcategory} 
          currentItemName={foodItem.item_name} 
        />
        <Grid
        container
        sx={{
          backgroundColor: "#eef0eb",
          mt: { xs: 1.2, md: 3 },
        }}
      >
        <Grid
          item
          xs={12}
          sm={5.4}
          md={5.4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ padding: "30px" }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography className="responsive_fontsize20" sx={{ mr: 1 }}>
                4.9
              </Typography>
              {stars.map((_, index) => (
                <StarIcon key={index} sx={{ color: "#EAA017" }} />
              ))}
              <Typography className="responsive_fontsize16" sx={{ ml: 1 }}>
                245 Views
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <ThumbUpIcon sx={{ cursor: "pointer", mr: 0.5 }} />
              <ThumbDownIcon sx={{ cursor: "pointer" }} />
            </Box>
            <Box>
              {feedbackData.map((feedback) => (
                <Box
                  key={feedback.id}
                  sx={{ display: "flex", alignItems: "center", mb: 2 }}
                >
                  <AccountCircleIcon sx={{ fontSize: 40, mr: 1 }} />
                  <Box>
                    <Typography
                      className="responsive_fontsize18"
                      sx={{ color: "#5a595c", fontWeight: "bold" }}
                    >
                      {feedback.name}
                    </Typography>
                    <Typography
                      className="responsive_fontsize18"
                      sx={{ mt: 0.5 }}
                    >
                      {feedback.comment}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
      </Box>
    </Box>
  );
}

export default Description;

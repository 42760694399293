import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

const DateTimeDialog = ({
  open,
  onClose,
  newDate,
  newTime,
  minTime,
  handleDateChange,
  handleTimeChange,
  handleDateTimeSubmit,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Date and Time</DialogTitle>
      <DialogContent sx={{ marginTop: "1rem" }}>
        <TextField
          label="Date"
          type="date"
          value={newDate}
          onChange={handleDateChange}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: new Date().toISOString().split("T")[0],
          }}
        />
        <TextField
          label="Time"
          type="time"
          value={newTime}
          onChange={handleTimeChange}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: minTime,
          }}
          sx={{ marginTop: "1rem" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDateTimeSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateTimeDialog;
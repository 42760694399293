import React, { useState } from "react";
import ButtonComponent from "src/components/ui/buttons/button_component";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LocalPizzaIcon from "@mui/icons-material/LocalPizza";

function CategoryButton({ categories, selectedCategory, setSelectedCategory }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getIcon = (category) => {
    switch (category) {
      case "Fast Food":
        return <FastfoodIcon />;
      case "Restaurant":
        return <RestaurantIcon />;
      case "Pizza":
        return <LocalPizzaIcon />;
      default:
        return <FastfoodIcon />;
    }
  };

  return (
    <>
      {/* Mobile View */}
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton onClick={handleMenuOpen} sx={{ color: "black" }}>
          <MenuIcon />
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {categories.map((category) => (
          <MenuItem
            key={category}
            onClick={() => handleCategoryChange(category)}
          >
            <ListItemIcon>{getIcon(category)}</ListItemIcon>
            <ListItemText primary={category} />
          </MenuItem>
        ))}
      </Menu>

      {/* Desktop and Tablet View */}
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          justifyContent: "center",
          gap: 1.5, // Adjusted gap for better spacing
          flexWrap: "wrap", // Allows buttons to wrap on smaller widths
        }}
      >
        {categories.map((category) => (
          <ButtonComponent
            key={category}
            className="responsive_fontsize16"
            variant="h5"
            onClick={() => handleCategoryChange(category)}
            sx={{
              border: "1px solid black",
              backgroundColor:
                selectedCategory === category ? "#000000" : "#ffffff",
              color: selectedCategory === category ? "#ffffff" : "#000000",
              borderRadius: "15px", // Slightly smaller border-radius
              fontWeight: "bold",
              fontFamily: "Roboto Serif",
              fontSize: {
                lg: "0.9rem",
                md: "0.75rem",
                sm: "0.65rem",
                xs: "0.55rem",
              }, // Reduced font size
              padding: { lg: "0.5rem 1rem", sm: "0.4rem 0.8rem", xs: "0.4rem" }, // Reduced padding
              transition: "all 0.3s ease-in-out",
              boxShadow:
                selectedCategory === category
                  ? "0px 4px 8px rgba(0, 0, 0, 0.2)"
                  : "0px 2px 4px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#000000",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                transform: "scale(1.05)",
              },
            }}
          >
            {category}
          </ButtonComponent>
        ))}
      </Box>
    </>
  );
}

export default CategoryButton;

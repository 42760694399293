import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import esewa from "../order_list/payment_assets/esewa_logo.png";
import khalti from "../order_list/payment_assets/khalti_logo.png";
import fonepay from "../order_list/payment_assets/fonepay_logo.png";
import mobBanking from "../order_list/payment_assets/mob_banking.png";
// import axios from "axios";

function CheckoutPayment({ onPaymentMethodChange }) {
  const [paymentMethod, setPaymentMethod] = useState("Cash On Delivery"); // Default payment method
  const [paymentResponse] = useState(null);

  const handlePaymentChange = (event) => {
    const selectedMethod = event.target.value;
    setPaymentMethod(selectedMethod);
    onPaymentMethodChange(selectedMethod); // Pass selected method to parent
  };

  return (
    <Box>
      <FormControl>
        <RadioGroup
          aria-labelledby="payment-method"
          value={paymentMethod}
          // onChange={(e) => setPaymentMethod(e.target.value)}
          onChange={handlePaymentChange}
          name="payment-method"
        >
          <FormControlLabel
            value="Cash On Delivery"
            control={<Radio />}
            label="Cash On Delivery"
          />
          <FormControlLabel
            value="Esewa"
            control={<Radio />}
            label={
              <img
                src={esewa}
                alt="eSewa logo"
                style={{ height: "20px", width: "70px" }}
              />
            }
          />
          <FormControlLabel
            value="Khalti"
            control={<Radio />}
            label={
              <img
                src={khalti}
                alt="Khalti logo"
                style={{ height: "30px", width: "90px" }}
              />
            }
          />
          <FormControlLabel
            value="FonePay"
            control={<Radio />}
            label={
              <img
                src={fonepay}
                alt="Fonepay logo"
                style={{ height: "28px", width: "100px" }}
              />
            }
          />
          <FormControlLabel
            value="Mobile Banking"
            control={<Radio />}
            label={
              <img
                src={mobBanking}
                alt="Mobile banking logo"
                style={{ height: "18px", width: "100px" }}
              />
            }
          />
        </RadioGroup>
      </FormControl>

      {paymentResponse && (
        <Box mt={2}>
          {paymentResponse.success ? (
            <Typography variant="h6" color="green">
              Payment successful! Redirecting...
              {/* Add your logic here to redirect or handle the payment response */}
            </Typography>
          ) : (
            <Typography variant="h6" color="red">
              Payment failed: {paymentResponse.message}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

export default CheckoutPayment;

import React, { useState } from "react";
import { Typography, Box, Button, Grid, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const MenuPopoverComponent = ({ categories, selectedCategory, onChange }) => {
  const [isOpen, setIsOpen] = useState(false); // Track menu open/close state
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleMenuToggle = () => {
    setIsOpen((prev) => !prev); // Toggle the menu open/close state
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isXs ? "center" : "flex-start",
          // backgroundColor:'red'
        }}
      >
        <Typography
          className="responsive_fontsize48"
          fontWeight="bold"
        >
          BBG SPECIAL
        </Typography>
        {isXs ? (
          <Button
            onClick={handleMenuToggle}
            sx={{
              marginLeft: "10px",
              cursor: "pointer",
              padding: 0,
              minWidth: "auto",
            }}
          >
            <MenuIcon
              sx={{
                fontSize: "1.5rem",
                color: "#000000",
              }}
            />
          </Button>
        ) : (
          categories.map((category) => (
            <Button
              key={category}
              onClick={() => onChange(category)}
              sx={{
                marginLeft: 1,
                fontWeight: "bold",
                borderRadius: "20px",
                backgroundColor:
                  selectedCategory === category ? "#000000" : "#ffffff",
                color:
                  selectedCategory === category ? "#ffffff" : "#000000",
                "&:hover": {
                  backgroundColor: "#000000",
                  color: "#ffffff",
                },
              }}
            >
              {category}
            </Button>
          ))
        )}
      </Box>

      {isXs && isOpen && (
        <Box sx={{ p: 2, mt: 1, borderRadius: "8px", backgroundColor: "#f5f5f5" }}>
          <Grid container direction="column" spacing={1}>
            {categories.map((category) => (
              <Grid item key={category}>
                <Button
                  onClick={() => {
                    onChange(category);
                    setIsOpen(false); // Close the menu after selecting a category
                  }}
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    fontWeight: "bold",
                    borderRadius: "20px",
                    backgroundColor:
                      selectedCategory === category ? "#000000" : "#ffffff",
                    color:
                      selectedCategory === category ? "#ffffff" : "#000000",
                    "&:hover": {
                      backgroundColor: "#000000",
                      color: "#ffffff",
                    },
                  }}
                >
                  {category}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default MenuPopoverComponent;

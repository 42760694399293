import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardActions,
  Button,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import ComboFood from "../../assets/comboFood.jpg"; // Adjust path as needed
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { css } from "@emotion/react";
import { fetchSubscriptions } from "../../redux/slices/subscriptionSlice"; // Adjust the path
import { fetchMenus } from "src/redux/slices/menuSlice";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SubscriptionSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState("");

  // Fetch subscriptions and menus from Redux store
  const { subscriptions, loading: subscriptionsLoading } = useSelector(
    (state) => state.subscriptions
  );

  const { selectedMenus, loading: menusLoading } = useSelector(
    (state) => state.menus
  );

  const loading = subscriptionsLoading || menusLoading;

  useEffect(() => {
    // Dispatch actions to fetch subscriptions and menus
    dispatch(fetchSubscriptions()); // Replace with your actual fetch action
    // dispatch(fetchMenus()); // Replace with your actual fetch action
  }, [dispatch]);

    useEffect(() => {
    // Dispatch action to fetch menus if not already fetched
    if (!selectedMenus || selectedMenus.length === 0) {
      dispatch(fetchMenus()); // Replace with your actual fetch action
    }
  }, [dispatch, selectedMenus]);

  const handleMenuChange = (event) => {
    setSelectedMenu(event.target.value);
  };

  const hasMenus = !menusLoading && selectedMenus?.length > 0;

  return (
    <div>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Slider
          {...sliderSettings}
          css={css`
            .slick-slide {
              padding: 0 1rem;
            }
            .slick-dots {
              bottom: -30px;
            }
          `}
        >
          {subscriptions && subscriptions.length > 0 ? (
            subscriptions.map((subscription, index) => (
              <div key={index}>
                <Card
                  sx={{
                    width: "100%",
                    maxWidth: "320px",
                    backgroundColor: "#261d1d",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "auto",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "white" }}
                    >
                      {subscription.subscription_type} Package
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2, color: "white" }}>
                      {subscription.description}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "white" }}>
                      (Only <b>{subscription.discount || 0}</b> Discount!)
                    </Typography>
                  </CardContent>

                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={ComboFood}
                      alt="Combo Food"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        borderRadius: "4px",
                      }}
                    />
                  </Box>

                  <CardActions
                    sx={{ justifyContent: "center", paddingBottom: "1rem" }}
                  >
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={6}>
                        {hasMenus ? (
                          <FormControl fullWidth>
                            <InputLabel id="menu-select-label">
                              Select Menu
                            </InputLabel>
                            <Select
                              labelId="menu-select-label"
                              value={selectedMenu}
                              onChange={handleMenuChange}
                              label="Select Menu"
                              sx={{ backgroundColor: "white" }}
                            >
                              {selectedMenus.map((menu) => (
                                <MenuItem
                                  key={menu.menu_id}
                                  value={menu.menuName}
                                >
                                  {menu.menuName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <Button
                            variant="contained"
                            sx={{
                              color: "white",
                              backgroundColor: "#000",
                              width: "100%",
                              padding: "10px",
                              borderRadius: "4px",
                              "&:hover": {
                                backgroundColor: "#333",
                              },
                            }}
                            onClick={() => navigate("/make-your-menu")}
                          >
                            Make a Menu
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          sx={{
                            color: "white",
                            backgroundColor: "orange",
                            width: "100%",
                            padding: "10px",
                            borderRadius: "4px",
                            "&:hover": {
                              backgroundColor: "#ff8c00",
                            },
                          }}
                          onClick={() =>
                            navigate(
                              `/availableplans/${subscription.subscription_type}`
                            )
                          }
                        >
                          Available plans
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </div>
            ))
          ) : (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", color: "white" }}
            >
              No subscriptions available.
            </Typography>
          )}
        </Slider>
      )}
    </div>
  );
};

export default SubscriptionSlider;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const url = "https://www.bbgrestaurant.com/bbgapi";

export const postTableData = createAsyncThunk(
  "tableBooking/postTableData",
  async (tableData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${url}/bookTable`, tableData);
      return response.data;
    } catch (error) {
      console.error("Error response:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchTableTiming = createAsyncThunk(
  "tableBooking/fetchTableTiming",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${url}/bookTable`);
      console.log("Response Data:", response.data);
      return response.data; // Ensure the backend returns `allItemsGot`
    } catch (error) {
      console.error(
        "Error fetching table timing:",
        error.response?.data || error.message
      );
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const tableBookingSlice = createSlice({
  name: "tableBooking",
  initialState: { loading: false, error: null, timings: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postTableData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postTableData.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postTableData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to book table.";
      })
      .addCase(fetchTableTiming.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTableTiming.fulfilled, (state, action) => {
        console.log("Fetched table timing:", action.payload);
        state.loading = false;
        state.timings = action.payload.allItemsGot || []; // Store fetched data
      })
      .addCase(fetchTableTiming.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch table timing.";
      });
  },
});

export default tableBookingSlice.reducer;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Box, Paper, Divider } from "@mui/material";
import MenuHeader from "./menuHeader";
import MenuItemList from "./menuItemListing";
import DateTimeDialog from "./dateTimeDialog";
import { useDispatch } from "react-redux";
import { updateMenuDateTime } from "src/redux/slices/menuSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addToCheckout } from "src/redux/slices/cartSlice";

function MakeMenuList({
  menuName,
  handleOpenDelete,
  handleMenuDelete,
  handleDeleteDialog,
  handleSaveMenuName,
  handleItemDelete
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const selectedMenus = useSelector((state) => state.menus.selectedMenus);
  console.log("selected menus", selectedMenus)
  const menu = useSelector((state) =>
        state.menus.selectedMenus.find((m) => m.menuName === menuName)
    );
    console.log("menu in selected items", menu)
  const currentMenu = useSelector((state) => state.menus.currentMenu);
  const specificMenu = useSelector((state) =>
    state.menus.selectedMenus.find((menu) => menu.menuName === menuName)
  );
  
  const menuDate = specificMenu?.menu_schedule?.date;
  const menuTime = specificMenu?.menu_schedule?.time;
  console.log("Specific Menu:", specificMenu);
  console.log("Menu Date:", menuDate);
  console.log("Menu Time:", menuTime);


  const date = selectedMenus?.menu_schedule?.date;
  const time = selectedMenus?.menu_schedule?.time;
  console.log("selected menus time and date", date, time);

  const handleOpenTimeDialog = () => {
    if (specificMenu && specificMenu.schedule) {
      setSelectedDate(specificMenu.schedule.date);
      setSelectedTime(specificMenu.schedule.time);
    }
    setIsDialogOpen(true);
  };

  const handleCloseTimeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleDateTimeSubmit = () => {
    if (currentMenu) {
      dispatch(updateMenuDateTime({
        menuId: currentMenu.menu_id,
        date: selectedDate,
        time: selectedTime,
      }));
    }
    setIsDialogOpen(false);
  };

  const [menuCardCounts, setMenuCardCounts] = useState({});
  React.useEffect(() => {
    if (selectedMenus.length > 0) {
      const counts = selectedMenus.reduce((acc, menu, index) => {
        acc[index] = menu.selectedItems ? menu.selectedItems.length : 0;
        return acc;
      }, {});
      setMenuCardCounts(counts);
    }
  }, [selectedMenus]);

  const handleAddToCheckout = () => {
    // Flatten the selected items across all menus to create a single list
    const allSelectedItems = selectedMenus.flatMap(menu => menu.selectedItems || []);
    console.log("all selected items in menu", allSelectedItems);
    
    // Map the selected items to the order structure
    const orderData = {
      order_items: allSelectedItems.map((item) => ({
        itemId: item.sid,  // Item's sid
        name: item.item_name,  // Item's name
        quantity: item.quantity,  // Item quantity
        price: Array.isArray(item.item_price) ? item.item_price[0] : item.item_price,  // Select the price, default to the first if it's an array
      })),
      order_quantity: 1,  // Or calculate the total quantity from the order items if needed
      total_price: calculateTotalPrice(allSelectedItems),  // Use a function to calculate the total price based on selected items
    };

    console.log("ordered data from menu", orderData);
  
    // Dispatch the action to add to checkout
    dispatch(addToCheckout(orderData));
  
    // Success message and navigate to checkout
    toast.success(`Menu ${selectedMenus.map(menu => menu.menu_name).join(", ")} added to checkout.`);
    navigate("/checkout");
  };
  
  // Helper function to calculate the total price of the order
  const calculateTotalPrice = (items) => {
    return items.reduce((total, item) => {
      // Multiply quantity by price for each item
      return total + (Array.isArray(item.item_price) ? item.item_price[0] : item.item_price) * item.quantity;
    }, 0).toFixed(2);  // Return as a string with two decimal places
  };
  

  if (!currentMenu) return null;

  return (
    <Box
      sx={{
        position: "relative",
        marginTop: "20px",
        padding: selectedMenus.length > 0 ? "20px" : "0",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        backgroundColor: "#f9f3e1",
        mr: 10,
      }}
    >
      <Typography
        className="responsive_fontsize28"
        variant="h5"
        sx={{
          textAlign: "center",
          marginBottom: "16px",
          fontFamily: "roboto serif",
        }}
      >
        Your Menu
      </Typography>

      {currentMenu && (
        <Paper elevation={3} sx={{ padding: "16px", marginBottom: "16px" }}>
          <Box>
            <MenuHeader
              currentMenu={currentMenu}
              currentMenuIndex={selectedMenus.indexOf(currentMenu)}
              handleTimeClick={handleOpenTimeDialog}
              menuDates={date}
              menuTimes={time}
              handleAddToCheckout={handleAddToCheckout}
              menuName={currentMenu}
            />

            <DateTimeDialog
              open={isDialogOpen}
              onClose={handleCloseTimeDialog}
              newDate={selectedDate}
              newTime={selectedTime}
              minTime="00:00"
              handleDateChange={handleDateChange}
              handleTimeChange={handleTimeChange}
              handleDateTimeSubmit={handleDateTimeSubmit}
            />

            <Divider sx={{ marginY: "16px" }} />
            <MenuItemList
              items={currentMenu.selectedItems}
              handleSaveMenuName={handleSaveMenuName}
              currentMenu={currentMenu}
              menuCardCounts={menuCardCounts}
              currentMenuIndex={selectedMenus.indexOf(currentMenu)}
              menuName={currentMenu}
              handleOpenDelete={handleOpenDelete}
              handleMenuDelete={handleMenuDelete}
              handleDeleteDialog={handleDeleteDialog}
              handleDeleteCard={handleItemDelete}
              handleAddToCheckout={handleAddToCheckout}
            />
          </Box>
        </Paper>
      )}
    </Box>
  );
}

export default MakeMenuList;

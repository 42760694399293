import React from 'react';
import { Box, Typography } from '@mui/material';
import CategoryButton from '../make_menu/categoryButton';

function HeaderSection({ selectedCategory, setSelectedCategory, categories }) {
 
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { sm: "row", md: "column" },
          gap: 2,
        }}
      >
        <Typography
          className="responsive_fontsize24"
          variant="h3"
          sx={{
            fontFamily: 'roboto serif',
            fontWeight: "bold",
            textAlign: "center",
            color: "green",
            textTransform: "uppercase",
            mb: 2,
          }}
        >
          {selectedCategory }
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <CategoryButton
            categories={categories} // item_name values passed as categories
             selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default HeaderSection;

import React from "react";
import { Typography, Box, IconButton } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const MenuHeader = ({
  currentMenu,
  handleAddToCheckout,
  handleTimeClick,
  currentMenuIndex,
  menuDates = [],
  menuTimes = [],
  menuName,
  handleMenuOrder
}) => {
  if (!currentMenu) {
    return null; // or some fallback UI
  }

  // Ensure the index is valid and within the array bounds
  const isValidIndex = 
  typeof currentMenuIndex === "number" &&
  currentMenuIndex >= 0 &&
  currentMenuIndex < menuDates.length &&
  currentMenuIndex < menuTimes.length;


  const handleOpenTimeDialog = () => {
    handleTimeClick(); // Open the DateTimeDialog
  };
  console.log({ currentMenuIndex, menuDates, menuTimes });


  return (
    <>
      <Typography
        className="responsive_fontsize16"
        variant="body1"
        sx={{ textAlign: "center", fontWeight: "bold" }}
      >
        Menu Name: {currentMenu}
      </Typography>

      <IconButton onClick={handleAddToCheckout}>
        <Typography>
          Place menu Order
          </Typography>
      </IconButton>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <IconButton onClick={handleOpenTimeDialog}>
          <AccessTimeIcon sx={{ fontSize: 20 }} />
        </IconButton>

        <Typography variant="subtitle1">
          Date: {isValidIndex ? menuDates[currentMenuIndex] : "Not Set"}
        </Typography>
        <Typography variant="subtitle1" sx={{ marginLeft: "10px" }}>
          Time: {isValidIndex ? menuTimes[currentMenuIndex] : "Not Set"}
        </Typography>
      </Box>
    </>
  );
};

export default MenuHeader;

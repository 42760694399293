import { Box, Grid } from '@mui/material'
import React from 'react'
import BookYourTable from './book_your_table'

function BookTable() {
  return (
    <Box sx={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"row",
        m: {
            xs: "7rem 0 0.5rem 0 ",
            sm: "7rem 0 0.5rem 0",
            md: "7rem 0 0.5rem 0 ",
            lg: "7rem 0 1rem 0",
          }
    }}>
      <Grid container justifyContent = "center" alignItems= "center" spacing = {2}>
        <Grid>
            <Box sx={{
                position: "relative",
                mt:5
            }}
            >
                <BookYourTable/>
            </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BookTable

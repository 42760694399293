import React, {
  useEffect,
  // useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Header from "./cardListingHeader";
import CategorySection from "./categorySection";
import FoodItemsGrid from "./foodItemsGrid";
import MenuContainer from "./menuContainer";
import {
  setAnchorElId,
  closePopover,
  setSelectedCategory,
  setCurrentMenuIndex,
  openMenuNameDialog,
  closeMenuNameDialog,
  addMenu,
  addItemToMenu,
  setMenuName,
  setFoodItemToAdd,
  fetchMenus,
} from "../../../../redux/slices/menuSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Utility function to parse item prices
const parsePrice = (itemPrice) => {
  try {
    const prices = JSON.parse(itemPrice);
    return Array.isArray(prices) ? prices[0] : 0;
  } catch (error) {
    console.error("Error parsing item_price:", error);
    return 0;
  }
};

function MakeMenu({ foodOrdered }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [menuSchedule, setMenuSchedule] = useState(null); // State for schedule

  const {
    selectedMenus,
    currentMenu,
    openPopover,
    selectedCategory,
    joinData,
    menuName,
    foodItemToAdd,
  } = useSelector((state) => state.menus);

  const categories = [
    ...new Set((joinData[0] || []).map((item) => item.item_name)),
  ];
  const { isAuthenticated } = useSelector((state) => state.auth);

  // const menu = useSelector((state) =>
  //   state.menus.selectedMenus.find((m) => m.menuName === menuName)
  // );

  const axiosInstance = axios.create({
    baseURL: "https://www.bbgrestaurant.com/bbgapi",
    headers: {
      "Content-Type": "application/json",
    },
  });

  useEffect(() => {
    if (selectedCategory) {
      dispatch(setSelectedCategory(selectedCategory));
    }
  }, [selectedCategory, dispatch]);

  const handleOpenMenuNameDialog = (event, fooditem) => {
    if (!isAuthenticated) {
      toast.error("Please login first to create a menu.");
      dispatch(closeMenuNameDialog());
      navigate("/login");
      return;
    }

    if (!event || !event.currentTarget || fooditem === undefined) {
      console.error("Event or food item is missing.");
      return;
    }

    const newAnchorId = event.currentTarget.id;
    const popoverID1 = fooditem ? fooditem.id : "header";
    const currentTime = new Date(); // Get current timestamp

    // Format current time to a human-readable format
    const formattedTime = currentTime.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    dispatch(setAnchorElId(newAnchorId));
    dispatch(closePopover());
    dispatch(setMenuName(""));
    dispatch(setFoodItemToAdd(fooditem || null));

    dispatch(
      openMenuNameDialog({
        anchorElId: newAnchorId,
        popoverID: popoverID1,
        foodItem: fooditem
          ? {
              ...fooditem,
              quantity: fooditem.quantity || 1,
              price: parsePrice(fooditem.item_price),
            }
          : null,
        creationTime: formattedTime, // Pass creation time
      })
    );
  };

  const handleSaveMenuName = async () => {
    if (!menuName.trim()) {
      toast.error("Menu name cannot be empty.");
      return;
    }

    if (selectedMenus.some((menu) => menu.menuName === menuName)) {
      toast.warn("Menu name already exists");
      dispatch(closeMenuNameDialog());
      return;
    }

    const currentTime = new Date(); // Get current tim
    // Format current time to a human-readable format
    const formattedTime = currentTime.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    const dateTimeJson = {
      date: currentTime.toLocaleDateString("en-US"),
      time: currentTime.toLocaleTimeString("en-US"),
    };

    console.log("Formatted Date and Time JSON:", JSON.stringify(dateTimeJson));
    console.log("date time json", dateTimeJson);
    console.log("date and time formateed", formattedTime);

    // Now you can use dateTimeJson in your data or API request

    dispatch(addMenu({ menuName }));
    // Log foodItemToAdd to check its properties
    console.log("Food Item to Add: ", foodItemToAdd);
     // Check if foodItemToAdd exists and log its data
  if (foodItemToAdd) {
    console.log("Adding food item with price:", parsePrice(foodItemToAdd.item_price));
    dispatch(addItemToMenu({ menuName, item: { foodItemToAdd, price: parsePrice(foodItemToAdd.item_price) } }));
  }
    const menuItems = [];
    let totalPrice = 0;
    let totalItemCount = 0;

    if (foodItemToAdd) {
      const itemPrice = parsePrice(foodItemToAdd.item_price);
      totalPrice += itemPrice;
      totalItemCount += 1;
      menuItems.push(foodItemToAdd.sid);
      console.log("Food item added:", foodItemToAdd, "Total price so far:", totalPrice, "Total item count:", totalItemCount);
    }

    selectedMenus.forEach((menu) => {
      if (menu.menuItems) {
        menu.menuItems.forEach((item) => {
          const itemPrice = item.price || 0;
          const itemQuantity = item.quantity || 1;
          totalPrice += itemPrice * itemQuantity;
          totalItemCount += itemQuantity;
          menuItems.push(item.sid);
          console.log("Added item from selected menu:", item, "Total price now:", totalPrice, "Total item count:", totalItemCount);
        });
      }
    });

    // Log the final saveMenu object
    console.log("Final menu items:", menuItems);
    console.log("Final total price:", totalPrice);
    console.log("Final total item count:", totalItemCount);

    const saveMenu = {
      menu_name: menuName,
      item_quantity: totalItemCount,
      item_total: totalPrice,
      menu_schedule: dateTimeJson,
      sid: menuItems,
      quantity: foodItemToAdd?.quantity || 1,
      total_price: totalPrice,
      menu_cid: localStorage.getItem("customerId"),
      table_number: 2,
    };
    console.log("Menu data to be saved:", saveMenu);

    try {
      // const response = await axiosInstance.post("/makeMenu", saveMenu);1
      const response = await axiosInstance.post("/menu", saveMenu);
      console.log("response in make menu", response);
      console.log("respoen data in make menu api", response.data)
      const newMenuId = response.data.data.menu_id;
      localStorage.setItem('menu_id', newMenuId);
      console.log(`New Menu ID ${newMenuId} saved in localStorage`);
      if (response.data) {
        toast.success("Menu created and saved.");
        dispatch(closeMenuNameDialog());
        dispatch(closePopover());
        dispatch(fetchMenus());
      } else {
        toast.error("Failed to save the menu.");
      }
    } catch (error) {
      toast.error("Error saving the menu.");
      console.error("Error saving the menu:", error);
    }
  };

  // const handleScheduleChange = (date) => {
  //   setMenuSchedule(date);
  // };

  const handleInputChange = (e) => {
    dispatch(setMenuName(e.target.value));
  };

  const handleClose = () => {
    dispatch(closeMenuNameDialog());
    dispatch(setMenuName(""));
    dispatch(setFoodItemToAdd(null));
  };

  return (
    <>
      <Header
        setAnchorEl={(el) => dispatch(setAnchorElId(el))}
        openPopover={openPopover}
        handleClosePopover={() => dispatch(closePopover())}
        selectedMenus={selectedMenus}
        handleOpenMenuNameDialog={handleOpenMenuNameDialog}
        handleInputChange={handleInputChange}
        handleSaveMenuName={handleSaveMenuName}
      />

      <CategorySection
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={(category) =>
          dispatch(setSelectedCategory(category))
        }
      />

      <FoodItemsGrid
        selectedCategory={selectedCategory}
        selectedMenus={selectedMenus}
        currentMenu={currentMenu}
        handleOpenMenuNameDialog={handleOpenMenuNameDialog}
        handleClose={handleClose}
        handleSaveMenuName={handleSaveMenuName}
        handleInputChange={handleInputChange}
      />

      <MenuContainer
        selectedMenus={selectedMenus}
        currentMenu={currentMenu}
        foodOrdered={foodOrdered}
        setCurrentMenuIndex={setCurrentMenuIndex}
      />
    </>
  );
}

MakeMenu.propTypes = {
  foodOrdered: PropTypes.array.isRequired,
  totalItemCount: PropTypes.number.isRequired,
};

MakeMenu.defaultProps = {
  foodOrdered: [],
};

export default MakeMenu;

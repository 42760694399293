import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import HeaderSection from "./HeaderSection";
import GridLayout from "./GridLayout";
import MobileSlider from "./MobileSlider";
import { setSelectedCategory } from "../../../../redux/slices/menuSlice";

function FirstSection() {
  const dispatch = useDispatch();
  const joinData = useSelector((state) => state.menus.joinData);
  const selectedCategory = useSelector((state) => state.menus.selectedCategory);

  const [searchedItems, setSearchedItems] = useState([]);

  useEffect(() => {
    if (!selectedCategory) {
      dispatch(setSelectedCategory("menu"));
    }
  }, [dispatch, selectedCategory]);

  useEffect(() => {
    if (joinData[0]) {
      const filteredItems = (joinData[0] || []).filter(
        (item) => item.item_name === selectedCategory
      );
      setSearchedItems(filteredItems);
    } else {
      setSearchedItems([]);
    }
  }, [selectedCategory, joinData]);

  const itemNames = [
    ...new Set((joinData[0] || []).map((item) => item.item_name)),
  ];

  return (
    <Box sx={{ padding: 1, mb: 10 }}>
      <HeaderSection
        selectedCategory={searchedItems.length > 0 ? selectedCategory : "Menu"}
        setSelectedCategory={(category) => {
          setSearchedItems([]);
          dispatch(setSelectedCategory(category));
        }}
        categories={itemNames}
      />

      {searchedItems.length > 0 ? (
        <>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <MobileSlider searchedItems={searchedItems} />
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <GridLayout searchedItems={searchedItems} />
          </Box>
          {/* Add-to-cart button */}
        </>
      ) : (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", fontFamily: "roboto serif" }}
        >
          No items available in the selected category.
        </Typography>
      )}
    </Box>
  );
}


export default FirstSection;

import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./slices/homeSlice";
import cartReducer from "./slices/cartSlice";
import loginReducer from "./reducers/login_reducers";
import menuReducer from "./slices/menuSlice"
import confirmOrderReducer from "./reducers/confirmOrderReducer";
import foodReducer from '../components/foodDescriptionPage/store/reducer';
import customerLocationReducer from './slices/customerLocation'
import authReducer from './slices/authSlice';
import profileReducer from './slices/profileSlice';
import subscriptionReducer from './slices/subscriptionSlice';
import tableBookingReducer from './slices/tableBookingSlice'; 

export const store = configureStore({
  //  middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //      serializableCheck: false, 
  //    }),
  reducer: {
    home: homeReducer,
    cart: cartReducer,
    login: loginReducer,
    menus : menuReducer,
    orderconfirm: confirmOrderReducer,
    food: foodReducer,
    customerLocation: customerLocationReducer,
    auth: authReducer,
    profile: profileReducer,
    subscriptions: subscriptionReducer,
    tableBooking:tableBookingReducer,
  },
});
export default store;

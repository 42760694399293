import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Popover,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  // addItemToMenu,
  closeMenuNameDialog,
  closePopover,
  fetchMenus,
  openConfirmDeleteDialog,
} from "src/redux/slices/menuSlice";
import DeleteMenuPopover from "./deleteMenuPopover";
import axios from "axios";
import { toast } from "react-toastify";

// Utility function to parse item prices
const parsePrice = (itemPrice) => {
  try {
    const prices = JSON.parse(itemPrice);
    return Array.isArray(prices) ? prices[0] : 0;
  } catch (error) {
    console.error("Error parsing item_price:", error);
    return 0;
  }
};


function FavoritePopover({
  open,
  onClosePopover,
  anchorEl,
  handleOpenMenuNameDialog,
  fooditem,
  handleOpenDelete,
  handleMenuDelete,
  handleDeleteDialog,
  deleteMenuName,
}) {
  const dispatch = useDispatch();
  const { selectedMenus = [], foodItemToAdd, confirmDeleteDialog } = useSelector((state) => state.menus);

  console.log("selected menus in popover", selectedMenus, foodItemToAdd);

  // Helper function to update the menu data with the new item or incremented quantity
  const updateMenuData = async (menu_id, menuData) => {
    try {
      const customerId = localStorage.getItem("customerId");
      if (!customerId || !menu_id) {
        throw new Error("Customer ID or Menu ID is missing.");
      }

      // Make the API PATCH request to update the menu
      const response = await axios.patch(
        `https://www.bbgrestaurant.com/bbgapi/menu/patch/${customerId}/${menu_id}`,
        menuData
      );
      console.log("API Request Payload:", menuData);
      console.log("API Response:", response.data);

      if (response?.data?.success === true) {
        toast.success("Menu updated successfully!");
        return { success: true, message: "Menu updated successfully!" };
      } else {
        toast.error(response.data.error || "Failed to update the menu.");
        throw new Error(response?.data?.error || "Failed to update the menu.");
      }
    } catch (error) {
      toast.error("Error updating the menu.");
      console.error("Error updating the menu:", error);
    }
  };

const handleMenuClick = async (menuName) => {
  try {
    const menu = selectedMenus.find((m) => m.menuName === menuName);
    if (!menu) throw new Error(`Menu "${menuName}" not found.`);

    // Ensure items array exists before accessing its length
    // const totalItemCount = menu.items ? menu.items.length : 0;
    // console.log("item count in total after update", totalItemCount, menu)

    // Avoid adding the same item multiple times
    const updatedItems = [...menu.selectedItems];
    const existingItemIndex = updatedItems.findIndex(
      (item) => item.sid === foodItemToAdd.sid
    );

    // Parse the price and calculate item_total
    const itemPrice = parsePrice(foodItemToAdd.item_price);
    if (itemPrice === 0) {
      console.error("Invalid item price:", foodItemToAdd.item_price);
    }

    // const itemTotal = itemPrice * (existingItemIndex > -1 ? updatedItems[existingItemIndex].quantity + 1 : 1);
    console.log("item total price", itemPrice);

    console.log("Existing item index in popover:", existingItemIndex);

    if (existingItemIndex > -1) {
      // Increment the quantity of the existing item
      updatedItems[existingItemIndex] = {
        ...updatedItems[existingItemIndex],
        quantity: updatedItems[existingItemIndex].quantity + 1,
        // price: itemPrice,
      };
    } else {
      // Add the new item if not already present
      updatedItems.push({
        ...foodItemToAdd,
        quantity: 1,
        price : itemPrice,
        sid: foodItemToAdd.sid, // Ensure new item's `sid` is an array
      });
    }

    // Calculate the total price of the updated menu
    // const totalPrice = updatedItems.reduce((total, item) => total + item.totalPrice, 0);

    // Prepare the API request payload
    const menuData = {
      menu_name: menuName,
      sid: foodItemToAdd.sid, // Send the selected `sid` of the item you're updating
      cid: localStorage.getItem('customerId'),
      menu_id: menu.menu_id,
      price: itemPrice,
    };

    console.log("API Request Payload:", menuData);

    // Send the updated menu data to the server
    const response = await updateMenuData(menu.menu_id, menuData);
    console.log("API Response:", response);

    if (response?.success) {
    //   dispatch(
    //     addItemToMenu({
    //       menuName,
    //       sid: foodItemToAdd.sid,
    //       total_price: itemPrice,
    //       // item_total: item_total, 
    //       action: existingItemIndex > -1 ? "increment" : "add",
    //     })
    //   );
      toast.success(
        `${foodItemToAdd.item_name} has been ${
          existingItemIndex > -1 ? "incremented in" : "added to"
        } ${menuName}`
      );
    } else {
      throw new Error(response?.message || "Failed to update the menu.");
    }

    // Close dialogs and fetch updated menus
    dispatch(closeMenuNameDialog());
    dispatch(closePopover());
    dispatch(fetchMenus());
  } catch (error) {
    toast.error("Error updating the menu.");
    console.error("Error in handleMenuClick:", error.message);
  }
};


  return (
    <>
      <Popover
        anchorEl={anchorEl}
        id={"favorite-popover" || fooditem.id}
        open={open}
        onClose={onClosePopover}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <IconButton onClick={handleOpenMenuNameDialog}>
              <AddIcon />
            </IconButton>
            <Typography variant="h6">Make Your Menu!</Typography>
          </Box>
          <List>
            {selectedMenus.map((menu) => (
              <ListItem
                button
                key={menu.menu_id} // Assuming each menu has a unique id
                onClick={() => handleMenuClick(menu.menuName)}
              >
                <ListItemText primary={menu.menuName} />

                {/* Deleting the menu via the popover */}
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() =>
                      dispatch(openConfirmDeleteDialog(menu.menuName))
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>

      {confirmDeleteDialog && (
        <DeleteMenuPopover
          open={confirmDeleteDialog}
          openDelete={handleOpenDelete}
          confirmDelete={handleMenuDelete}
          closeDelete={handleDeleteDialog}
          deleteMenuName={deleteMenuName}
        />
      )}
    </>
  );
}

FavoritePopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onClosePopover: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  handleOpenMenuNameDialog: PropTypes.func.isRequired,
  fooditem: PropTypes.object.isRequired,
  handleOpenDelete: PropTypes.func.isRequired,
  handleMenuDelete: PropTypes.func.isRequired,
  handleDeleteDialog: PropTypes.func.isRequired,
  deleteMenuName: PropTypes.string,
};

FavoritePopover.defaultProps = {
  anchorEl: null,
  deleteMenuName: "",
};

export default FavoritePopover;
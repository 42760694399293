import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  addToCheckout,
  moveCheckoutToCart,
  addToCart,
} from "src/redux/slices/cartSlice";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import CardIngredients from "./card_ingredients";
import CardName from "./card_name";

// Utility function to convert image buffer to base64
const bufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

function HomeCard({
  food,
  sliderIndex,
  ingredientAnimateClass,
  setIngredientAnimateClass,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Image conversion to base64 for display
  const base64Image = food.item_image
    ? `data:image/png;base64,${bufferToBase64(food.item_image.data)}`
    : "/path/to/default/image.jpg"; // Default image if none exists

  const getCurrentPrice = () => {
    const hour = new Date().getHours();
    const isDay = hour >= 6 && hour < 18;

    let prices = [];
    try {
      if (food.item_price) {
        prices = JSON.parse(food.item_price);
      }
    } catch (e) {
      console.error("Error parsing item_price:", e);
      return 0; // Return 0 if there's an error
    }

    // Return the appropriate price
    if (prices.length > 1) {
      return isDay ? prices[0] : prices[1];
    } else if (prices.length === 1) {
      return prices[0];
    } else {
      return 0; // Return 0 if no prices are available
    }
  };

  const price = getCurrentPrice();

  // Function to handle adding the item to cart and checkout
  const handleAddToOrder = () => {
    const concatenatedSubcategory =
      `${food.item_subcategory} ${food.item_name}`.trim();

    const itemToAdd = {
      id: food.item_id,
      subcategory: concatenatedSubcategory,
      price: price, // Ensure this has a safe fallback
      quantity: 1,
      image: base64Image,
      detail: food.item_description,
    };

    // Dispatch actions to add to both cart and checkout
    dispatch(addToCheckout(itemToAdd)); // Add to checkout
    dispatch(addToCart(itemToAdd)); // Add to cart

    // Show a success toast immediately
    toast.success(`${concatenatedSubcategory} added to checkout and cart!`);

    // Navigate to checkout
    navigate("/checkout");
  };

  // Move items from checkout to cart if not on the checkout page
  useEffect(() => {
    if (location.pathname === "/checkout") {
      dispatch(moveCheckoutToCart());
    }
  }, [location.pathname, dispatch]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: 4, sm: 4 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          height: "100%",
          flexDirection: { xs: "column-reverse", sm: "row" },
        }}
      >
        <CardName
          food={food}
          price={price}
          handleAddToCart={handleAddToOrder}
        />

        <Box
          sx={{
            width: {
              xs: "80%", // 80% width on extra-small screens
              sm: "60%", // 60% width on small screens
              md: "60%", // Reduced size on medium screens
              lg: "60%", // Further reduce size on large screens
              xl: "35%", // Smallest size on extra-large screens
            },
            height: { xs: "auto", sm: "auto", md: "auto" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: { xs: 2, sm: 0 }, // Add margin for smaller screens
          }}
        >
          <img
            src={base64Image || ""}
            alt={food.subcategory ? food.subcategory.join(", ") : ""}
            style={{
              width: "100%", // Set width to 100% of the container
              height: "auto", // Maintain the aspect ratio
              borderRadius: "50%", // Circular image
              maxWidth: "300px", // Set a maximum width for the image on large screens
            }}
          />
        </Box>

        {/* Ingredients section */}
        <CardIngredients
          food={food}
          sliderIndex={sliderIndex}
          ingredientAnimateClass={ingredientAnimateClass}
          setIngredientAnimateClass={setIngredientAnimateClass}
        />
      </Box>
    </Container>
  );
}

export default HomeCard;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

import VerifyPhone from "./verifyPhone";
import OtpDialog from "./otpDialog";

const ForgotPasswordDialog = ({ open, onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOtpDialog, setShowOtpDialog] = useState(false);

  const handlePhoneVerified = () => {
    setShowOtpDialog(true); // Switch to OTP dialog after phone verification.
  };

  const handleClose = () => {
    setPhoneNumber("");
    setShowOtpDialog(false);
    onClose();
  };

  return (
    <>
      {/* Show the Phone Verification dialog if OTP dialog is not open */}
      {!showOtpDialog && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <Typography variant="h5" sx={{ color: "green" }}>
              Forgot Password?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <VerifyPhone
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              onVerified={handlePhoneVerified}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* OTP Dialog */}
      {showOtpDialog && (
        <OtpDialog
          open={showOtpDialog}
          onClose={(otpVerified) => {
            if (otpVerified) {
              handleClose(); // Close everything if OTP is verified.
            } else {
              setShowOtpDialog(false); // Return to Phone Verification if OTP is not verified.
            }
          }}
          phoneNumber={phoneNumber}
        />
      )}
    </>
  );
};

export default ForgotPasswordDialog;

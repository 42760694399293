import React, { useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import MenuName from "./menuNames";
import MakeMenuList from "../makeMenuItems/selectedItems";
import DeleteMenuPopover from "./deleteMenuPopover";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  openConfirmDeleteDialog,
  closeConfirmDeleteDialog,
  setCurrentMenu,
  openItemDeleteDialog,
  closeItemDeleteDialog,
  fetchMenus,
  deleteMenuFromServer,
  deleteMenuItemFromServer
} from "src/redux/slices/menuSlice";
import DeleteItemPopover from "../FoodCard/deleteItemPopover";

const MenuContainer = ({
  confirmDelete,
  foodOrdered,
  setSelectedMenus,
  setCurrentMenuIndex,
  handleSaveMenuName,
}) => {
  const dispatch = useDispatch();

  const { selectedMenus, currentMenu, confirmDeleteDialog, openItemDelete, loading, error } = useSelector(
    (state) => state.menus
  );

  useEffect(() => {
    if (!loading && selectedMenus.length === 0) {
      dispatch(fetchMenus());
      console.log("Fetched menus on load or after deletion.");
    }
  }, [dispatch, selectedMenus.length, loading]);

  const handleMenuSelection = (menuName, menu_id) => {
    localStorage.setItem('menu_id', menu_id);
    if (typeof menuName === "string") {
      dispatch(setCurrentMenu(menuName, menu_id));
      setCurrentMenuIndex(
        selectedMenus.findIndex((menu) => menu.menuName === menuName)
      );
    } else {
      console.error("Expected a string for menuName");
    }
  };

  const handleOpenDeleteDialog = () => {
    dispatch(openConfirmDeleteDialog());
    console.log("opening delete dialog from popover.");
  };

  const handleOpenItemDeleteDialog = () => {
    dispatch(openItemDeleteDialog());
    console.log("opening the item delete dialog popover.")
  }

  const handleMenuDeleteConfirm = async () => {
    try {
      await dispatch(deleteMenuFromServer(currentMenu)); // Wait for deletion to complete
    } catch (error) {
      console.error("Error deleting menu:", error);
    } finally {
      dispatch(closeConfirmDeleteDialog()); // Close the dialog regardless of success
      dispatch(fetchMenus());
    }
  };

  const handleItemDelete = async () => {
    try{
      await dispatch(deleteMenuItemFromServer(currentMenu.sid));
    }catch(error){
      console.error(`Error deleting items from menu ${currentMenu}`);
    }finally{
      dispatch(closeItemDeleteDialog());
      dispatch(fetchMenus());
    }
  }

  const handleDeleteDialogCancel = () => {
    dispatch(closeConfirmDeleteDialog());
    console.log("closing menu from popover.");
  };

  const handleCloseDeleteItemDialog = () => {
    dispatch(closeItemDeleteDialog());
    console.log("closing the item delete popover.");
  }

// Render loading state
if (loading) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <CircularProgress />
    </Box>
  );
}

  if (error) {
    return (
      <Box sx={{ padding: "20px", textAlign: "center" }}>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </Box>
    );
  }

   // Render when no menus are available
   if (selectedMenus.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <Typography variant="h6" color="textSecondary">
          No menus available. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
        ml: 2,
      }}
    >
      {selectedMenus && selectedMenus.length > 0 && (
        <MenuName
          selectedMenus={selectedMenus}
          handleMenuSelection={handleMenuSelection}
          handleMenuDeleteConfirm={handleMenuDeleteConfirm}
          open={confirmDeleteDialog}
        />
      )}

      {currentMenu && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <MakeMenuList
            currentMenu={currentMenu}
            selectedMenus={selectedMenus}
            foodOrdered={foodOrdered}
            setSelectedMenus={setSelectedMenus}
            setCurrentMenuIndex={setCurrentMenuIndex}
            deleteMenuName={currentMenu}
            handleOpenDelete={handleOpenDeleteDialog}
            handleMenuDelete={handleMenuDeleteConfirm}
            handleDeleteDialog={handleDeleteDialogCancel}
            handleSaveMenuName={handleSaveMenuName}
            handleItemDelete={handleItemDelete}
          />
        </Box>
      )}

      {confirmDeleteDialog && (
        <DeleteMenuPopover
          open={confirmDeleteDialog}
          confirmDelete={confirmDelete}
          deleteMenuName={currentMenu}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
          handleMenuDeleteConfirm={handleMenuDeleteConfirm}
          handleDeleteDialogCancel={handleDeleteDialogCancel}
        />
      )}

      {openItemDelete && (
        <DeleteItemPopover
          open = {openItemDelete}
          confirmDeleteItem = {confirmDelete}
          deleteMenuName={currentMenu}
          deleteItem= {selectedMenus.selectedItems}
          handleOpenDialog = {handleOpenItemDeleteDialog}
          handleItemDelete= {handleItemDelete}
          handleClose = {handleCloseDeleteItemDialog}
        />
      )}
    </Box>
  );
};

MenuContainer.propTypes = {
  currentMenu: PropTypes.object,
  handleMenuSelect: PropTypes.func.isRequired,
  handleDeleteMenu: PropTypes.func.isRequired,
  confirmDeleteDialog: PropTypes.bool.isRequired,
  deleteMenuName: PropTypes.string.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  foodOrdered: PropTypes.array.isRequired,
  setSelectedMenus: PropTypes.func.isRequired,
  setCurrentMenuIndex: PropTypes.func.isRequired,
};

export default MenuContainer;

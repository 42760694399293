import React from "react";
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails  } from "@mui/material";
import { ExpandMore as ExpandMoreIcon, LocalActivity as LocalActivityIcon} from "@mui/icons-material";

const referredPersons = [
  { name: "John Doe", email: "john.doe@example.com" },
  { name: "Jane Smith", email: "jane.smith@example.com" },
];

const ReferAccordion = () => (
  <Accordion sx={{
    boxShadow: "none",
    backgroundColor:"transparent",
    borderRadius: 0,
    border: "none",
    borderBottom: "1px solid gray",
    padding: "0px",}}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="refer-content" id="refer-header">
      <LocalActivityIcon sx={{ mr: 1 }} />
      <Typography>Refer</Typography>
    </AccordionSummary>
    <AccordionDetails>
      {referredPersons.map((person, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <Typography variant="body1">{person.name}</Typography>
          <Typography variant="body2" color="textSecondary">{person.email}</Typography>
        </Box>
      ))}
    </AccordionDetails>
  </Accordion>
);

export default ReferAccordion;

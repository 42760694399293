  import React from "react";
  import { useSelector, useDispatch } from "react-redux";
  import { Grid, Box, Typography } from "@mui/material";
  import axios from "axios";
  import { toast } from "react-toastify";
  import FoodCard from "../FoodCard/FoodCard";
  import {
    decrementMenuItemQuantity,
    incrementMenuItemQuantity,
    // removeItemFromMenu,
  } from "src/redux/slices/menuSlice";

  const MenuItemList = ({
    // items,   
    handleAddToCheckout,
    handleDeleteCard,
    menuName,
    handleOpenDelete,
    handleMenuDelete,
    handleDeleteDialog,
  }) => {
    const dispatch = useDispatch();

    const menu = useSelector((state) =>
      state.menus.selectedMenus.find((m) => m.menuName === menuName)
    );

    // Define the item count here
    const itemCount = menu?.selectedItems?.length || 0;

    if (!menuName) {
      console.error("Menu name is undefined.");
      return <Typography>Menu name is not defined.</Typography>;
    }

    if (!menu) {
      console.error(`Menu with name "${menuName}" not found.`);
      return <Typography>No menu found with name "{menuName}".</Typography>;
    } else {
      // console.log("There is a menu available with items", menu.items, menuName);
    }

        // Function to update the menu on the server after quantity change
  const updateMenuOnServer = async (menuName, itemId, action) => {
    try {
      const menuItem = menu.selectedItems.find((item) => item.sid === itemId);
      console.log("menus in update menu server", menuItem)
      if (!menuItem) return;

      const customerId = localStorage.getItem("customerId");
      if (!customerId) throw new Error("Customer ID is missing.");

      // const updatedQuantity = action === 'increment' ? menuItem.quantity + 1 : menuItem.quantity - 1;
      // const updatedItem = {
      //   sid: menuItem.sid,
      //   quantity: updatedQuantity,
      //   item_total: updatedQuantity * menuItem.price,
      // };
      // console.log("Updated item details for patch:", updatedItem);

      const response = await axios.patch(
        `https://www.bbgrestaurant.com/bbgapi/menu/patch/${customerId}/${menu.menu_id}/${menu.sid}`,
        {
          // customerId, 
          // menu_id: menu.menu_id,
          // sid: menuItem.sid, 
          operation: action, 
          // ...updatedItem 
        }
      );
      console.log("response in menu itme listing for server item update quantity", response.data)

      if (response.data.msg === "Item quantity updated successfully.") {
        toast.success(`Item quantity ${action}ed successfully!`);
        return response.data;  // Return the updated item data from the response
      }else {
        console.error('Error updating item quantity on server:', response.data.error);
        toast.error(response.data.error || "Failed to update item quantity.");
        return null;
      }
    } catch (error) {
      toast.error("Error updating the menu.");
      console.error(error);
    }
  };

  // Handle incrementing the quantity of a menu item.
  const handleIncrement = async (itemId) => {
    console.log("Handling increment for item:", itemId);
    const updatedItem = await updateMenuOnServer(menuName, itemId, 'increment');
    if (updatedItem) {
      dispatch(incrementMenuItemQuantity({ menuName, itemId, updatedItem }));
    } else {
      console.error("Failed to update item quantity on server.");
    }
  };

  // Handle decrementing the quantity of a menu item.
  const handleDecrement = async (itemId) => {
    console.log("Handling decrement for item:", itemId);
    const updatedItem = await updateMenuOnServer(menuName, itemId, 'decrement');
    if (updatedItem) {
      dispatch(decrementMenuItemQuantity({ menuName, itemId, updatedItem }));
    } else {
      console.error("Failed to update item quantity on server.");
    }
  };

    return (
      <>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          {menu.selectedItems && menu.selectedItems.length >= 1 ? (
            menu.selectedItems.map((item, index) =>
              item ? (
                <Grid item key={index}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* Pass item data to FoodCard */}
                    <FoodCard
                      key={item.id}
                      fooditem={item}
                      subcategory={item.item_subcategory}
                      quantity={item.quantity}
                      handleDeleteCard={() => handleDeleteCard(item.sid)}
                      handleIncrement={() => handleIncrement(item.sid)}
                      handleDecrement={() => handleDecrement(item.sid)}
                      handleOpenDelete={handleOpenDelete}
                      handleMenuDelete={handleMenuDelete}
                      handleDeleteDialog={handleDeleteDialog}
                    />
                  </Box>
                </Grid>
              ) : null
            )
          ) : (
            <Typography>No items available.</Typography>
          )}
        </Grid>
        <Typography
          variant="h6"
          className="responsive_fontsize14"
          sx={{ textAlign: "center", fontFamily: "roboto serif" }}
        >
          Total No of Food Items In Menu: {itemCount || 0}
        </Typography>
      </>
    );
  };

  export default MenuItemList;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  IconButton,
  Divider,
  Button,
  Grid,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EnquiryDialog from "./enquiry/enquiryForm";
import ReferDialog from "./referSection/referForm";
import PaymentMethodSection from "src/components/order_list/payment_section";

function FooterBar() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with:", { name, email });
  };

  const [openEnquiry, setOpenEnquiry] = useState(false);
  const [openRefer, setOpenRefer] = useState(false);

  const handleEnquiryClick = () => {
    setOpenEnquiry(true);
  };

  const handleReferClick = () => {
    setOpenRefer(true);
  };

  const handleClose = () => {
    setOpenEnquiry(false);
    setOpenRefer(false);
  };

  const navigate = useNavigate();
  const handleTrackClick = () => {
    navigate("/track");
  };
  const handleAboutClick = () => {
    navigate("/about");
  };
  const handleContactClick = () => {
    navigate("/contact");
  };
  const handleOfferClick = () => {
    navigate("/offer");
  };
  const handleFAQClick = () => {
    navigate("/faq");
  };
  const handleSubscriptionClick = () => {
    navigate("/subscription");
  };
  const handleMenuClick = () => {
    navigate("/menu");
  };
  const handleMakeYourMenuClick = () => {
    navigate("/make-your-menu");
  };

  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <Grid
        container
        spacing={3}
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          paddingTop: "3rem",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box>
            <Typography
              className="responsive_fontsize20"
              onClick={handleAboutClick}
              sx={{ fontFamily: "Roboto Serif", cursor: "pointer" }}
            >
              About
            </Typography>
            <Typography
              className="responsive_fontsize20"
              onClick={handleContactClick}
              sx={{
                fontFamily: "Roboto Serif",
                mt: "0.9rem",
                cursor: "pointer",
              }}
            >
              Contact
            </Typography>
            <Typography
              className="responsive_fontsize20"
              onClick={handleMenuClick}
              sx={{
                fontFamily: "Roboto Serif",
                mt: "0.9rem",
                cursor: "pointer",
              }}
            >
              Menu
            </Typography>
            <Typography
              className="responsive_fontsize20"
              onClick={handleOfferClick}
              sx={{
                fontFamily: "Roboto Serif",
                mt: "0.9rem",
                cursor: "pointer",
              }}
            >
              Offers
            </Typography>
            <Typography
              className="responsive_fontsize20"
              onClick={handleSubscriptionClick}
              sx={{
                fontFamily: "Roboto Serif",
                mt: "0.9rem",
                cursor: "pointer",
              }}
            >
              Subscription
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              className="responsive_fontsize20"
              sx={{
                fontFamily: "Roboto Serif",
              }}
            >
              Follow Us On
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                gap: 2, // Adds space between the icons
              }}
            >
              <IconButton>
                <FacebookIcon />
              </IconButton>
              <IconButton>
                <InstagramIcon />
              </IconButton>
              <IconButton>
                <YouTubeIcon />
              </IconButton>
            </Box>
            <Typography
              className="responsive_fontsize20"
              sx={{
                fontFamily: "Roboto Serif",
                mt: "2rem",
              }}
            >
              Pay with
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <PaymentMethodSection />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box>
            <Typography
              className="responsive_fontsize20"
              onClick={handleMakeYourMenuClick}
              sx={{
                fontFamily: "Roboto Serif",
                cursor: "pointer",
              }}
            >
              Make Your Menu
            </Typography>
            <Typography
              className="responsive_fontsize20"
              onClick={handleTrackClick}
              sx={{
                cursor: "pointer",
                fontFamily: "Roboto Serif",
                mt: "0.9rem",
              }}
            >
              Order
            </Typography>
            <Typography
              className="responsive_fontsize20"
              onClick={handleTrackClick}
              sx={{
                cursor: "pointer",
                fontFamily: "Roboto Serif",
                mt: "0.9rem",
              }}
            >
              Track Your Order
            </Typography>
            <Typography
              open={openRefer}
              onClick={handleReferClick}
              className="responsive_fontsize20"
              sx={{
                cursor: "pointer",
                fontFamily: "Roboto Serif",
                mt: "0.9rem",
              }}
            >
              Refer
            </Typography>
            <ReferDialog
              open={openRefer}
              onClose={handleClose}
              handleSubmit={handleSubmit}
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
            />
            <Typography
              className="responsive_fontsize20"
              onClick={handleFAQClick}
              sx={{
                fontFamily: "Roboto Serif",
                mt: "0.9rem",
                cursor: "pointer",
              }}
            >
              FAQ
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: "1rem",
          padding: "0.5rem 0.5rem",
        }}
      >
        <span
          style={{
            width: "20%",
          }}
        >
          <Button
            className="responsive_fontsize16"
            variant="outlined"
            fullWidth
            sx={{
              color: "#000",
              fontFamily: "Roboto Serif",
              textTransform: "capitalize",
              borderRadius: "0.625rem",
              height: "2.2rem",
            }}
            open={openEnquiry}
            onClick={handleEnquiryClick}
          >
            Enquiry
          </Button>
        </span>
      </Box>
      <EnquiryDialog
        open={openEnquiry}
        onClose={handleClose}
        handleSubmit={handleSubmit}
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
      />
      <Divider />
      <Grid
        container
        spacing={3}
        sx={{
          display: "flex",
          justifyContent: "space-around",
          mt: "2rem",
          paddingBottom: "1rem",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Typography
            className="responsive_fontsize12"
            sx={{
              fontFamily: "Roboto Serif",
            }}
          >
            Privacy & Policies
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className="responsive_fontsize14"
            sx={{
              fontFamily: "Roboto Serif",
            }}
          >
            Copyright &copy; {currentYear}, Basyal Bhojan Ghar
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            className="responsive_fontsize16"
            sx={{
              fontFamily: "Roboto Serif",
            }}
          >
            Powered by BlackMoon
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}

export default FooterBar;

import { createSlice } from '@reduxjs/toolkit';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    profilePicture: '',
    loading: false,
    error: null,
    data : null,
  },
  reducers: {
    fetchProfileRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchProfileSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchProfileFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateProfilePicture: (state, action) => {
      state.profilePicture = action.payload;
      if (state.data) {
        state.data.customer_profile = action.payload;
      }
    },
  },
});

export const {
  fetchProfileRequest,
  fetchProfileSuccess,
  fetchProfileFailure,
  updateProfilePicture,
} = profileSlice.actions;

export default profileSlice.reducer;

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeItem,
  incrementQuantity,
  decrementQuantity,
} from "../../redux/slices/cartSlice";
import {
  Box,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { toast } from "react-toastify";
// internal imports
import OrderConfirmationItems from "./order_confirmation_item";
import CheckoutPayment from "./checkout_payment";
import ButtonComponent from "../ui/buttons/button_component";
import logo from "../../assets/logo.jpg";
import ConfirmationAlert from "./dialog_confirm/confirmation_alert";
import {
  clearCart,
  clearOrderList,
  clearOrderedItems,
  confirmOrder,
} from "../../redux/actions/cartAction";
import DeliveryAddress from "./dialog_confirm/delivery_address";
import {
  fetchProfileRequest,
  fetchProfileSuccess,
  fetchProfileFailure,
} from "src/redux/slices/profileSlice";

const DELIVERY_CHARGE_FREE_DISTANCE = 4; // km
const DELIVERY_CHARGE = 50; // Rs.
const BBG_COORDINATES = { lat: 27.6663, lng: 85.333 };

// Axios instance
const axiosInstance = axios.create({
  baseURL: "https://www.bbgrestaurant.com/bbgapi",
  headers: { "Content-Type": "application/json" },
});
// Interceptor to add auth token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

function CheckoutSection() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.profile);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const orderedList = useSelector((state) => state.cart.items);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [deviceCoordinates, setDeviceCoordinates] = useState(null);
  const [deliveryDetails, setDeliveryDetails] = useState({
    fullName: data?.customer_name || "",
    mobileNumber: data?.customer_contact || "",
    emailAddress: data?.customer_email || "",
    locationText: "",
    foodInstructions: "",
    addressInstructions: "",
  });
  console.log("customer name initial", deliveryDetails);
  const [paymentMethod, setPaymentMethod] = useState("Cash On Delivery"); // Default payment method
  const handlePaymentMethodChange = (selectedMethod) => {
    setPaymentMethod(selectedMethod);
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchProfileSuccess();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (data) {
      console.log("Fetched profile data:", data);
      setDeliveryDetails({
        fullName: data.customer_name || "",
        mobileNumber: data.customer_contact || "",
        emailAddress: data.customer_email || "",
        locationText: "",
        foodInstructions: "",
        addressInstructions: "",
      });
    }
  }, [data]);

  // Fetch Profile Data
  useEffect(() => {
    const fetchProfile = async () => {
      dispatch(fetchProfileRequest());
      const customerId = localStorage.getItem("customerId");
      if (!customerId || !isAuthenticated) {
        toast.error("Authentication error. Please log in again.");
        return;
      }
      try {
        const response = await axiosInstance.get(`/customer/${customerId}`);
        if (response.data && response.data.data) {
          dispatch(fetchProfileSuccess(response.data.data));
          console.log("fetched data: ", response.data.data);
          setDeliveryDetails({
            fullName: response.data.data.fullName || "",
            mobileNumber: response.data.data.mobile || "",
            emailAddress: response.data.data.email || "",
            locationText: "",
            foodInstructions: "",
            addressInstructions: "",
          });
          console.log("response in checkout", response.data.data);
        } else {
          throw new Error("Invalid response data");
        }
      } catch (err) {
        dispatch(fetchProfileFailure(err.message));
        toast.error("Failed to load profile.");
      }
    };

    if (isAuthenticated) fetchProfile();
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    const totalCount = orderedList.reduce(
      (total, item) => total + item.quantity,
      0
    );
    setTotalItemCount(totalCount);
  }, [orderedList]);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  const handleDistanceCalculation = useCallback((deviceCoords) => {
    if (deviceCoords) {
      const distance = calculateDistance(
        deviceCoords.lat,
        deviceCoords.lng,
        BBG_COORDINATES.lat,
        BBG_COORDINATES.lng
      );
      console.log(`Distance calculated: ${distance} km`);
      const charge =
        distance <= DELIVERY_CHARGE_FREE_DISTANCE ? 0 : DELIVERY_CHARGE;
      setDeliveryCharge(charge); // Update delivery charge based on distance
    }
  }, []);

  useEffect(() => {
    if (deviceCoordinates) {
      handleDistanceCalculation(deviceCoordinates);
    }
  }, [deviceCoordinates, handleDistanceCalculation]);

  const handleDeviceCoordinatesUpdate = (coords) => {
    setDeviceCoordinates(coords); // Trigger distance calculation when device coordinates are updated
  };

  const handleConfirmOrder = async () => {
    if (!isAuthenticated) {
      toast.error("Please login first to place an order.");
      navigate("/login");
      return;
    }
    if (
      !deliveryDetails.fullName ||
      !deliveryDetails.mobileNumber ||
      !deliveryDetails.locationText
    ) {
      toast.error("Please complete all delivery details.");
      return;
    }
    handleDetailsUpdate();
    setOpenConfirmationDialog(true);
  };

  const placeOrder = async () => {
    const orderData = {
      order_items: orderedList.map((item) => ({
        name: item.subcategory,
        quantity: item.quantity,
      })),
      order_quantity: totalItemCount,
      total_price: totalAmount(),
      order_cid: localStorage.getItem("customerId"),
      track_status: "pending", // default
      payment_mode: paymentMethod,
      table_no: 3,
      order_instructions: deliveryDetails.foodInstructions,
      customer_detail: {
        name: deliveryDetails.fullName,
        address: deliveryDetails.locationText,
        phone: deliveryDetails.mobileNumber,
        email: deliveryDetails.emailAddress,
      },
      order_address_instruction: deliveryDetails.addressInstructions,
    };

    try {
      const response = await axiosInstance.post(`/joinOrder`, orderData);
      console.log("Order Data:", orderData);
      console.log("Order Response:", response); // Log the entire response
      // Check the actual response structure to confirm success
      if (response.data.success) {
        toast.success("Order placed successfully.");
        setOpenConfirmationDialog(true);
        dispatch(confirmOrder());
        handleDetailsUpdate(); // Update details if necessary
        dispatch(clearCart());
        dispatch(clearOrderList());
        dispatch(clearOrderedItems());
      } else {
        toast.error("Order not saved. Please try again.");
      }
    } catch (error) {
      console.error("Error in placing order", error);
      toast.error("Failed to place order");
    }
  };
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleRemoveItem = (id) => {
    dispatch(removeItem(id));
  };

  const handleIncrement = (id) => {
    dispatch(incrementQuantity(id));
  };

  const handleDecrement = (id) => {
    dispatch(decrementQuantity(id));
  };

  const handleDetailsUpdate = (updatedDetails) => {
    setDeliveryDetails((prevState) => ({
      ...prevState,
      ...updatedDetails,
    }));
  };

  const calculateTotalPrice = () => {
    return orderedList
      .reduce((total, item) => {
        return total + item.price * item.quantity; // Use the price stored in the item
      }, 0)
      .toFixed(2);
  };

  const totalAmount = () => {
    return (parseFloat(calculateTotalPrice()) + deliveryCharge).toFixed(2);
  };

  return (
    <>
      <Box
        sx={{
          mt: 10,
          display: "flex",
          flexDirection: "column",
          padding: { xs: "2rem 4rem", md: "2rem 10rem" },
          background: "#FCFCFC",
        }}
      >
        <Box sx={{ padding: "1rem", display: "flex", flexDirection: "column" }}>
          <Typography
            className="responsive_fontsize32"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "roboto serif",
              fontWeight: "600",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ height: "45px", width: "45px", marginRight: "1rem" }}
            />
            ORDER INVOICE
          </Typography>
        </Box>

        <Box
          sx={{ padding: "1rem 0", display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              className="responsive_fontsize24"
              sx={{ mb: "0.8rem", color: "green" }}
            >
              {" "}
              Order List
            </Typography>
            <Typography className="responsive_fontsize20" sx={{ mb: "0.8rem" }}>
              {" "}
              Total Items: {totalItemCount}
            </Typography>
          </Box>
          <Divider sx={{ mb: "1rem" }} />
          <Box>
            <OrderConfirmationItems
              orderedList={orderedList}
              handleRemoveItem={handleRemoveItem}
              handleIncrement={handleIncrement}
              handleDecrement={handleDecrement}
            />
          </Box>
        </Box>

        <Box>
          <Typography
            className="responsive_fontsize24"
            sx={{ mb: "0.8rem", color: "green" }}
          >
            {" "}
            Delivery Address{" "}
          </Typography>
          <Divider sx={{ mb: "1rem" }} />
          <Box>
            <DeliveryAddress
              onDistanceCalculated={handleDeviceCoordinatesUpdate}
              userName={deliveryDetails.fullName}
              address={deliveryDetails.locationText}
              mobile={deliveryDetails.mobileNumber}
              email={deliveryDetails.emailAddress}
              paymentMethod={paymentMethod}
              onAddressUpdate={(newAddress) =>
                setDeliveryDetails((prev) => ({
                  ...prev,
                  locationText: newAddress,
                }))
              }
              onDetailsUpdate={setDeliveryDetails} // Directly use state setter
            />
          </Box>
        </Box>

        <Box
          sx={{
            padding: "1rem 2rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            className="responsive_fontsize24"
            sx={{ mb: "0.8rem", color: "green" }}
          >
            {" "}
            Payment{" "}
          </Typography>
          <Divider sx={{ mb: "1rem" }} />
          <CheckoutPayment onPaymentMethodChange={handlePaymentMethodChange} />
        </Box>

        <Box sx={{ padding: "1rem", display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: "1rem",
              gap: 2,
            }}
          >
            <ButtonComponent
              className="responsive_fontsize22"
              variant="contained"
              style={{ textTransform: "none" }}
              size="large"
              sx={{ background: "gray" }}
              onClick={() => navigate(`/menu`)}
            >
              Go Back
            </ButtonComponent>
            <ButtonComponent
              className="responsive_fontsize22"
              onClick={handleConfirmOrder}
              variant="contained"
              style={{ textTransform: "none" }}
              size="large"
            >
              Confirm Order
            </ButtonComponent>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={openConfirmationDialog}
        fullScreen={fullScreen}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="responsive-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
            height: "auto",
            padding: "1rem",
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="responsive_fontsize24"
          fontWeight="bold"
          textAlign="center"
        >
          {" "}
          Confirm your Order
        </DialogTitle>
        <DialogContent>
          <ConfirmationAlert
            orderedList={orderedList}
            totalAmount={parseFloat(calculateTotalPrice()).toFixed(2)}
            deliveryCharge={deliveryCharge}
            open={openConfirmationDialog}
            onClose={handleCloseConfirmationDialog}
            totalPrice={totalAmount()}
            deliveryDetails={deliveryDetails}
            paymentMethod={paymentMethod}
            handleConfirmOrder={placeOrder}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CheckoutSection;

import React, { useState, useRef, useEffect } from "react";
import { Box, Grid, Typography, CircularProgress,Container} from "@mui/material";
import axios from 'axios';
import SearchBar from "../../../components/search_bar/search_bar_main";
import SocialMediaBar from "../../../components/social_media_bar/social_media_bar_main";
import ProfileHeader from "./profileHeader";
import OrdersList from "./orderList";
import MenusList from "./menuList"; // Ensure correct import path
import SettingPage from "./setting/settingPage";
import TrackStatus from '../../../section/section_track/track_section_order_details';
const url = 'https://www.bbgrestaurant.com/bbgapi';
// // Axios instance
// const axiosInstance = axios.create({
//     baseURL: 'https://www.bbgrestaurant.com/bbgapi',
//     headers: {
//         'Content-Type': 'application/json',
//     },
// });

// Add a request interceptor to include the auth token
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

function ProfileLoadPage() {
    // State variables
    const [profilePicture, setProfilePicture] = useState("");
    const [numberOfOrders, setNumberOfOrders] = useState(0);
    const [menusMade, setMenusMade] = useState(0); // Updated to reflect actual count
    const [userName, setUserName] = useState("");
    const [address, setAddress] = useState("");
    const [openOrders, setOpenOrders] = useState(false);
    const [openMenus, setOpenMenus] = useState(false);
    const [customerMadeMenus, setCustomerMadeMenus] = useState([]);
    const [orders, setOrders] = useState([]);
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true); // Added loading state

    // Fetch profile, orders, and menus on component mount
    useEffect(() => {
        const fetchProfile = async () => {
            const customerId = localStorage.getItem("customerId");
            // console.log("Customer ID:", customerId);
            if (!customerId) {
                console.error("Customer ID is missing");
                setIsLoading(false);
                return;
            }
            try {
                setIsLoading(true);
                // Fetch profile data
                const profileResponse = await axios.get(`${url}/customer/${customerId}`);
                const profile = profileResponse.data.data;
                setUserName(profile.customer_name);
                setAddress(profile.customer_address);

                // Convert Buffer to Base64 for profile picture
                if (profile.customer_profile && profile.customer_profile.data) {
                    const base64String = btoa(
                        String.fromCharCode(...new Uint8Array(profile.customer_profile.data))
                    );
                    setProfilePicture(`data:image/jpeg;base64,${base64String}`);
                }

                const ordersResponse = await axios.get(`${url}/joinOrder/customer/${customerId}/orders`);
                // console.log("Orders response:", ordersResponse.data);
                const fetchedOrders = ordersResponse.data.data;
                setNumberOfOrders(fetchedOrders.length);
                setOrders(fetchedOrders);

                const menusResponse = await axios.get(`${url}/makeMenu/menu/${customerId}`);
                console.log("Menus response:", menusResponse.data);
                const fetchedMenus = menusResponse.data.data;
                // Parse menu_items data and handle N/A issue
                const parsedMenus = fetchedMenus.map((menu) => {
                    let menuItems = [];
                    try {
                        // Try parsing menu_items (if it's a JSON string)
                        menuItems = JSON.parse(menu.menu_items);
                    } catch (e) {
                        console.error('Error parsing menu_items:', e);
                    }
                    return {
                        ...menu,
                        menu_items: menuItems,
                    };
                });
                setCustomerMadeMenus(parsedMenus);
                setMenusMade(fetchedMenus.length);
                setIsLoading(false);
            } catch (err) {
                console.error("Error fetching profile, orders, or menus data:", err);
                setIsLoading(false);
            }
        };
        fetchProfile();
    }, []);

    // Handle profile picture change
    const handleProfilePictureChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const newProfilePicture = e.target.result;
                setProfilePicture(newProfilePicture); // Update the preview
    
                const customerId = localStorage.getItem("customerId");
                if (!customerId) {
                    console.error("Customer ID is missing");
                    return;
                }
    
                try {
                    const formData = new FormData();
                    formData.append("customer_profile", file);
    
                    // Perform the API call to update the profile picture
                    const response = await axios.patch(`${url}/customer/${customerId}`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
    
                    if (response.status === 200) {
                        console.log("Profile picture updated successfully");
                    } else {
                        console.error("Failed to update profile picture");
                    }
                } catch (error) {
                    console.error("Error updating profile picture:", error);
                }
            };
            reader.readAsDataURL(file);
        }
    };    

    // Handle edit profile button click
    const handleEditProfileClick = () => {
        fileInputRef.current?.click();
    };

    // Handle clicking on the Number of Orders
    const handleNumberOfOrdersClick = async () => {
        const customerId = localStorage.getItem("customerId");
        console.log("Fetching orders for customer ID:", customerId);
        const url1 = `${url}/joinOrder/customer/${customerId}/orders`; // Ensure this is the correct URL
        console.log("Fetching orders from URL:", url1);

        if (!openOrders) { // Only fetch orders if we're opening the list
            try {
                const ordersResponse = await axios.get(`${url}/joinOrder/customer/${customerId}/orders`);
                console.log("Orders response in profile load:", ordersResponse.data);

                const fetchedOrders = ordersResponse.data.data; // Adjust based on your API response structure
                setOrders(fetchedOrders); // Update the orders state with fetched data
                setNumberOfOrders(fetchedOrders.length); // Update the number of orders
            } catch (error) {
                if (error.response) {
                    console.error("Error fetching orders:", error.response.data);
                    console.error("Status code:", error.response.status);
                } else {
                    console.error("Network or other error:", error);
                }
            }
        }
        // Toggle the orders list visibility
        setOpenOrders((prev) => !prev);
    };

    // Handle clicking on the Menus Made
    const handleMenusMadeClick = () => {
        setOpenMenus((prev) => !prev);
        if (!openMenus) {
            setOpenOrders(false);
        }
    };

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 12 }}>
                <CircularProgress />
            </Box>
        );
    }
    console.log("Hello",customerMadeMenus);
    return (
        <Container>
        <Box sx={{ mt: 12 }}>
            <SearchBar />
            <SocialMediaBar />
            <Grid
                container
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "10px",
                }}
            >
                <Grid item xs={12} sm={4} md={4}>
                    <ProfileHeader
                        profilePicture={profilePicture}
                        userName={userName}
                        address={address}
                        onEditProfileClick={handleEditProfileClick}
                        fileInputRef={fileInputRef}
                        onProfilePictureChange={handleProfilePictureChange}
                    />
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleProfilePictureChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4} sx={{ marginTop: "10px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={handleNumberOfOrdersClick}
                    >
                        <Typography className="responsive_fontsize20" variant="body1">
                            {numberOfOrders}
                        </Typography>
                        <Typography className="responsive_fontsize20" variant="body1">
                            Number of Orders
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4} sx={{ marginTop: "10px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={handleMenusMadeClick}
                    >
                        <Typography className="responsive_fontsize20" variant="body1">
                            {menusMade}
                        </Typography>
                        <Typography className="responsive_fontsize20" variant="body1">
                            Menus Made
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={9}>
                    {openOrders && <OrdersList orders={orders} />}
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item xs={12} md={9}>
                    {openMenus && <MenusList customerMadeMenus={customerMadeMenus || []} />} {/* Added fallback */}
                </Grid>
            </Grid>
            <TrackStatus orders={orders}/>
            <Box sx={{marginTop:{sx:'5px',md:'10px'}}}>
                <SettingPage />
            </Box>  
        </Box>
        </Container>
    );
}

export default ProfileLoadPage;
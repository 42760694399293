import React ,{useState} from "react";
import Slider from "react-slick";
import { Box } from "@mui/material";
import FoodCard from "../FoodCard/FoodCard";

const SliderComponent = ({ items }) => {
  const [sliderIndex, setSliderIndex] = useState(0);
  // console.log("items",items);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    beforeChange: (current, next) => setSliderIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: (i) => {
      return (
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            background: i === sliderIndex ? "#656566" : "lightgray",
            display: "inline-block",
            margin: "0 5px",
          }}
        />
      );
    },
    appendDots: (dots) => {
      // Limit the number of dots to a maximum of 5
      const maxDotsToShow = 5;
      const limitedDots = dots.slice(0, maxDotsToShow);
      return (
        <ul style={{ display: "flex", justifyContent: "center", padding: 0 }}>
          {limitedDots}
        </ul>
      );
    },
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Slider {...settings}>
        {items.map((fooditem, index) => (
          <Box key={`${fooditem.id}-${index}`}>
            <FoodCard
              fooditem={fooditem}
              subcategory={`${fooditem.item_subcategory} ${fooditem.item_type} ${fooditem.item_name}`} 
              price={fooditem.item_price}
              image={fooditem.item_image} 
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default SliderComponent;

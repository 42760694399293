import React, { useState } from "react";
import { TextField, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const VerifyPhone = ({ phoneNumber, setPhoneNumber, onVerified }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  

  const handleVerifyPhoneNumber = async () => {
    setError("");
    setLoading(true);
    try {
      await axios.post(
        `https://www.bbgrestaurant.com/bbgapi/users/verify-contact`,
        {
          customer_contact: phoneNumber,
        }
      );
      toast.success("Phone number verified successfully!");
      onVerified(); // Notify parent of successful verification.
      
    } catch (err) {
      setError(err.response?.data?.error || "Failed to verify phone number.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TextField
        label="Enter Phone Number"
        fullWidth
        variant="outlined"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        error={!!error}
        helperText={error}
        disabled={loading}
        margin="dense"
      />
      <Button
        onClick={handleVerifyPhoneNumber}
        color="primary"
        disabled={loading || !phoneNumber}
        fullWidth
        sx={{ mt: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : "Verify Phone"}
      </Button>
    </>
  );
};

export default VerifyPhone;

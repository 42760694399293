import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { Notifications as NotificationsIcon, ExpandMore as ExpandMoreIcon} from "@mui/icons-material";

const NotificationAccordion = () => (
  <Accordion sx={{
    boxShadow: "none",
    backgroundColor:"transparent",
    borderRadius: 0,
    border: "none",
    borderBottom: "1px solid gray",
    padding: "0px",
    }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="notifications-content" id="notifications-header">
      <NotificationsIcon sx={{ mr: 1 }} />
      <Typography>Notifications</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography variant="body1">You have new notifications.</Typography>
    </AccordionDetails>
  </Accordion>
);

export default NotificationAccordion;

import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Slider from "react-slick";
import FoodCard from "src/views/menu/components/FoodCard/FoodCard";
import "slick-carousel/slick/slick.css";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";

const RelatedCardSlider = ({ currentSubcategory, currentItemName }) => {
  const [sliderIndex, setSliderIndex] = React.useState(0);
  const joinData = useSelector((state) => state.menus.joinData);

  console.log("currentSubcategory:", currentSubcategory);
  console.log("currentItemName:", currentItemName);
  console.log("Join Data:", joinData);

  useEffect(() => {
    // Optionally log or debug the fetched subcategory
    console.log("Current item subcategory:", currentSubcategory);
  }, [currentSubcategory]);

  // Make sure joinData is defined and has the expected structure
  if (!joinData || !Array.isArray(joinData[0])) {
    return <div>No data available</div>; // Handle no data case
  }

  // Filter the related cards based on the current subcategory
  const filteredCards = joinData[0]?.filter((fooditem) => {
    const itemSubcategory = fooditem.item_subcategory?.toLowerCase() || fooditem.item_name?.toLowerCase();
    return itemSubcategory === currentSubcategory.toLowerCase();
  });

  console.log("Filtered Card:",filteredCards);
  // Check if there are any filtered cards
  if (!filteredCards || filteredCards.length === 0) {
    return <div>No related items found</div>; // Handle no filtered cards case
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    beforeChange: (_, next) => setSliderIndex(next),
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: i === sliderIndex ? "#656566" : "lightgray",
          display: "inline-block",
          margin: "0 5px",
        }}
      />
    ),
    appendDots: (dots) => (
      <ul style={{ display: "flex", justifyContent: "center", padding: 0 }}>
        {dots.slice(0, 5)}
      </ul>
    ),
  };

  return (
    <Box
      className="center__carousel"
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <Box sx={{ mt: "3.5rem 2rem" }} className="slick__popular">
        <Slider {...settings}>
          {filteredCards.map((fooditem, index) => (
            <Grid item key={`${fooditem.item_id}-${index}`}>
              <Box sx={{ position: "relative" }}>
                <FoodCard
                  fooditem={fooditem}
                  subcategory={`${fooditem.item_subcategory} ${fooditem.item_name}`}
                  price={fooditem.item_price}
                  image={fooditem.item_image}
                />
              </Box>
            </Grid>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default RelatedCardSlider;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  IconButton,
  MenuItem,
  Typography,
  Box,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import {
  postTableData,
  fetchTableTiming,
} from "../../../redux/slices/tableBookingSlice";
import axios from "axios";

const url = "https://www.bbgrestaurant.com/bbgapi";

const BookYourTable = ({ userName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, timings } = useSelector(
    (state) => state.tableBooking
  );

  const [formData, setFormData] = useState({
    number_of_customer: "",
    table_number: "",
    timing: "",
    booking_menu: "",
    additional_description: "",
  });

  const [localUserName, setLocalUserName] = useState("");

  useEffect(() => {
    const fetchUserName = async () => {
      const customerId = localStorage.getItem("customerId");
      if (!customerId) return;
      try {
        const response = await axios.get(`${url}/customer/${customerId}`);
        setLocalUserName(response.data.data.customer_name);
      } catch (error) {
        console.error("Error fetching username:", error);
      }
    };
    fetchUserName();
  }, []);

  useEffect(() => {
    // Fetch timings for all tables when the component loads
    dispatch(fetchTableTiming());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "table_number") {
      dispatch(fetchTableTiming(value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.number_of_customer <= 0) {
      alert("Please enter a valid number of customers.");
      return;
    }

    const menuItems = formData.booking_menu.split(",").map((item, index) => ({
      [`order${index + 1}`]: item.trim(),
    }));

    const formattedData = {
      ...formData,
      booking_menu: menuItems,
      cid: localStorage.getItem("customerId"),
    };

    try {
      await dispatch(postTableData(formattedData));
      alert("Your table has been booked successfully!");
      setFormData({
        number_of_customer: "",
        table_number: "",
        timing: "",
        booking_menu: "",
        additional_description: "",
      });
    } catch (error) {
      console.error("Booking failed", error);
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      weekday: "short", // Day of the week
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const filteredTimings = timings.filter((timing) => {
    const bookingTime = new Date(timing.timing);
    return bookingTime > new Date(); // Compare with current time
  });

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        {/* Form Section */}
        <Grid item xs={12} md={8}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              maxWidth: "100%",
              padding: 3,
              boxShadow: 4,
              borderRadius: 2,
              backgroundColor: "#fff",
              position: "relative",
            }}
          >
            <Typography
              variant="h5"
              mb={2}
              textAlign="center"
              fontWeight="bold"
              color="primary"
            >
              Book Your Table
            </Typography>
            <Box sx={{ position: "absolute", top: 16, right: 16 }}>
              <IconButton
                onClick={() => navigate("/contact")}
                sx={{
                  color: "black",
                  "& .MuiSvgIcon-root": { fontSize: 28 },
                }}
              >
                <CallIcon color="primary" />
              </IconButton>
            </Box>

            <Typography variant="h6" gutterBottom>
              Welcome, {localUserName || userName || "Guest"}!
            </Typography>

            <TextField
              label="Number of Customers"
              name="number_of_customer"
              value={formData.number_of_customer}
              onChange={handleChange}
              type="number"
              fullWidth
              margin="normal"
              inputProps={{ min: 1 }}
            />

            <TextField
              label="Table Number"
              name="table_number"
              value={formData.table_number}
              onChange={handleChange}
              select
              fullWidth
              margin="normal"
              required
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((table) => (
                <MenuItem key={table} value={table}>
                  Table {table}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Timing"
              name="timing"
              type="datetime-local"
              value={formData.timing}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
            />

            <TextField
              label="Food Ordered"
              name="booking_menu"
              value={formData.booking_menu}
              onChange={handleChange}
              placeholder="List items (e.g., Pizza, Pasta)"
              fullWidth
              margin="normal"
            />

            <TextField
              label="Additional Description"
              name="additional_description"
              value={formData.additional_description}
              onChange={handleChange}
              multiline
              rows={3}
              fullWidth
              margin="normal"
              placeholder="Any special requests or notes?"
            />

            {loading && (
              <Typography color="primary">Booking your table...</Typography>
            )}
            {error && <Typography color="error">Error: {error}</Typography>}

            <Box mt={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Book Table
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* Timing Section */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              // boxShadow: 4,
              borderRadius: 2,
              // backgroundColor: "#f9f9f9",
              padding: 2,
            }}
          >
            {filteredTimings.length > 0 ? (
              <>
                <Typography
                  variant="h6"
                  textAlign="center"
                  mb={2}
                  fontWeight="bold"
                  color="primary"
                >
                  Booked Tables Timings
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="responsive_fontsize16"
                          align="center"
                          sx={{ fontWeight: "bold" }}
                        >
                          S.N
                        </TableCell>
                        <TableCell
                          className="responsive_fontsize16"
                          align="center"
                          sx={{ fontWeight: "bold" }}
                        >
                          Table Number
                        </TableCell>
                        <TableCell
                          className="responsive_fontsize16"
                          align="center"
                          sx={{ fontWeight: "bold" }}
                        >
                          Timing
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredTimings.map((timing, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {timing.table_number}
                          </TableCell>
                          <TableCell align="center">
                            {formatDate(timing.timing)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Typography textAlign="center" color="textSecondary">
                No upcoming bookings.
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BookYourTable;

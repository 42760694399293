import React from "react";
import { Box, Button, Typography} from "@mui/material";
import { useNavigate } from "react-router-dom";

function TrackDetail(){
    const navigate=useNavigate();
    const handleTrackClick=()=>{
      navigate("/track");
    }
    return(
    <>
    <Box
        sx={{
          mt: "1.37rem",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "1.25rem",
              fontWeight: "700",
              fontFamily: "Roboto Serif",
            }}
          >
            Order Detail
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              mt: "0.3rem",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontFamily: "Roboto Serif" }}>
              Order ID :
            </Typography>
            <Typography
              sx={{ fontSize: "0.875rem", fontFamily: "Roboto Serif" }}
            >
              2080-05-15-001x
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              mt: "0.3rem",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontFamily: "Roboto Serif" }}>
              Address :
            </Typography>
            <Typography
              sx={{ fontSize: "0.875rem", fontFamily: "Roboto Serif" }}
            >
              Sanepa-02, Lalitpur
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              mt: "0.3rem",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontFamily: "Roboto Serif" }}>
             Before :
            </Typography>
            <Typography
              sx={{ fontSize: "0.875rem", fontFamily: "Roboto Serif" }}
            >
              2:00 PM
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              mt: "0.3rem",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontFamily: "Roboto Serif" }}>
              Status:
            </Typography>
            <Typography
              sx={{ fontSize: "0.875rem", fontFamily: "Roboto Serif" }}
            >
              On The Way
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              mt: "0.3rem",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontFamily: "Roboto Serif" }}>
              Description :
            </Typography>
            <Typography
              sx={{ fontSize: "0.875rem", fontFamily: "Roboto Serif" }}
            >
              Near By EEC, 300m
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: "1rem" }}>
        <Button onClick={handleTrackClick}
          variant="contained"
          sx={{
            width: "80%",
            textTransform: "capitalize",
            backgroundColor: "#000",
            color: "#fff",
            borderRadius: "1.25rem",
          }}
        >
          {" "}
          More Detail
        </Button>
      </Box>
      </>
)}
export default TrackDetail;
import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ReviewsIcon from "@mui/icons-material/Reviews";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import reviewsData from "./reviewData";
import ReviewsComponent from "./reviewsComponent";

const ReviewsAccordion = () => {
  const [showReviews, setShowReviews] = useState(false);
  const [reviews, setReviews] = useState([]);

  const handleReviewsClick = () => {
    if (!showReviews) {
      setReviews(reviewsData); // Set reviews from data source (API call, etc.)
    }
    setShowReviews(!showReviews); // Toggle the visibility of the reviews section
  };

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        backgroundColor: "transparent",
        borderRadius: 0,
        border: "none",
        borderBottom: "1px solid gray",
        padding: "0px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="reviews-content"
        id="reviews-header"
        onClick={handleReviewsClick}
      >
        <ReviewsIcon sx={{ mr: 1 }} />
        <Typography className="responsive_fontsize18">Reviews</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {showReviews && (
          <ReviewsComponent reviews={reviews} onClose={handleReviewsClick} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ReviewsAccordion;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchMenus = createAsyncThunk('menus/fetchMenus', async () => {
  try {
    const customerId = localStorage.getItem('customerId');
    const menu_id = localStorage.getItem('menu_id');
    console.log("menu id in slicer", menu_id);
    const response = await axios.get(`https://www.bbgrestaurant.com/bbgapi/menu/customer/${customerId}`);
    // console.log('response data data from menu slice', response.data.data);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menus:", error);
    throw error; // Rethrow to trigger the rejected state
  }
});

export const fetchMenusById = createAsyncThunk('menus/fetchMenusById', async () => {
  try {
    const customerId = localStorage.getItem('customerId');
    const menu_id = localStorage.getItem('menu_id');
    console.log("menu_id fetched in fetchMenusById:", menu_id);
    if (!menu_id) {
      throw new Error("menu_id is missing in localStorage");
    }
    const response = await axios.get(`https://www.bbgrestaurant.com/bbgapi/menu/customer/${customerId}`);
    console.log("response data in fetch menus by id", response.data, response, response.data.data)
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menus:", error);
    throw error; // Rethrow to trigger the rejected state
  }
});

// Delete Menu from Server
export const deleteMenuFromServer = createAsyncThunk(
  'menus/deleteMenuFromServer',
  async (_, { getState, rejectWithValue }) => {
    try {
      const customerId = localStorage.getItem('customerId');
      // const state = getState(); // Get the current state
      // const menuId = state.menus.menuId; // Use the menuId stored in the state
      const menuId = localStorage.getItem('menu_id')
      if (!customerId || !menuId) {
        throw new Error('Missing Customer ID or Menu ID');
      }

      const response = await axios.delete(
        `https://www.bbgrestaurant.com/bbgapi/menu/delete/${customerId}/${menuId}`
      );
      console.log("Delete response:", response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

//deleing item 
export const deleteMenuItemFromServer = createAsyncThunk(
  'menus/deleteMenuFromServer',
  async ({ rejectWithValue }) => {
    try {
      const customerId = localStorage.getItem('customerId');
      const menu_id = localStorage.getItem('menu_id');
      const sid = localStorage.getItem('sid');
      console.log("menu id in slicer", menu_id);
      const response = await axios.delete(`https://www.bbgrestaurant.com/bbgapi/menu/delete/${customerId}/${menu_id}/${sid}`);
      console.log("respoen in deleting menu items" , response)
      return response; // Return the deleted menu name to handle removal from the state
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const url='https://www.bbgrestaurant.com/bbgapi';
const initialState = {
  menus: [],
  selectedMenus: [],
  currentMenu: null,
  currentMenuIndex: null,
  openMenuDialog: false,
  confirmDeleteDialog: false,
  openItemDelete: false,
  deleteMenuName: '',
  menuName: '',
  anchorElId: null,
  selectedCategory: 'Chowmein',
  foodItemToAdd: null,
  popoverID: null,
  newDate: "",
  newTime: "",
  location: { lat: null, lon: null },
  menuDates: [],
  menuTimes: [],
  joinData: [],
  loading: false,
  error: null,
  selectedItems: []
};

const menuSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    addMenu: (state, action) => {
      const { menuName } = action.payload;
      if (!state.selectedMenus.some(menu => menu.menuName === menuName)) {
        const newMenu = { menuName, selectedItems: [] };
        state.selectedMenus.push(newMenu);
      }
    },   
    addItemToMenu: (state, action) => {
      const { menuName, items} = action.payload;
      const menu = state.selectedMenus.find((menu) => menu.menuName === menuName);
    
      if (menu) {
        if (Array.isArray(items)) {
          items.forEach((newItem) => {
            // Find the existing item by its sid
            const existingItem = menu.items.find((item) => item.sid === newItem.sid);
            console.log("existing item in slicer", existingItem)
            if (existingItem) {   
              existingItem.totalPrice = existingItem.quantity * existingItem.price;
            } else {
              // Add new item as a distinct entry
              console.log("new item in slicer", newItem)
              menu.items.push({
                ...newItem,
                quantity: newItem.quantity || 1, // Default quantity to 1 if not provided
                totalPrice: (newItem.quantity || 1) * newItem.price,
              });
            }
          });
    
          // Update the total price for the menu
          menu.totalPrice = menu.items.reduce(
            (total, item) => total + item.totalPrice,
            0
          );
        } else {
          console.warn("`items` is not a valid array in addItemToMenu", items);
        }
        state.menu_id = menu.menu_id;
      } else {
        console.error("Menu not found for menuName:", menuName);
      }
    },    
    updateMenu: (state, action) => {
      const { menuName, item } = action.payload;
      console.log('updateMenu payload:', action.payload);
      if (!menuName || !item) {
        console.error("Invalid payload for updateMenu:", action.payload);
        return;
      }
    
      let menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (!menu) {
        menu = { menuName, selectedItems: [] };
        state.selectedMenus.push(menu);
      } else {
        const existingItem = menu.selectedItems.find(i => i.id === item.id);
        if (existingItem) {
          existingItem.quantity += item.quantity;
        } else {
          menu.selectedItems.push({ ...item });
        }
      }
      if (state.currentMenu?.menuName === menuName) {
        state.currentMenu = { ...menu };
      }
    },      
    setMenuDate: (state, action) => {
      const { menuName, date } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        menu.date = date;
        console.log("menu date", date)
      } else {
        console.error(`Menu "${menuName}" not found.`);
      }
    },
    setMenuTime: (state, action) => {
      const { menuName, time } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        menu.time = time;
        console.log("menu time", time)
      } else {
        console.error(`Menu "${menuName}" not found.`);
      }
    },
    updateMenuDateTime: (state, action) => {
      const { menuName, date, time } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        menu.date = date;
        menu.time = time;
      }
    },
    // Delete menu
    deleteMenu: (state, action) => {
      const menuName = action.payload;
      state.selectedMenus = state.selectedMenus.filter(menu => menu.menuName !== menuName);
      if (state.currentMenu?.menuName === menuName) {
        state.currentMenu = null;
      }
      console.log("delete menu name", deleteMenu)
    },
    incrementMenuItemQuantity: (state, action) => {
      const { menuName, itemId } = action.payload;
      const menu = state.selectedMenus.find((m) => m.menuName === menuName);
    
      if (!menu) {
        console.error(`Menu "${menuName}" not found.`);
        return;
      }
      console.log("menu in slice increment", menu)
    
      const item = menu.selectedItems?.find((i) => i.sid === itemId);
      if (item) {
        item.quantity += 1;
        // menu.totalPrice += item.price; // Update total price
      } else {
        console.error(`Item with sid "${itemId}" not found in menu "${menuName}".`);
      }
    },
    decrementMenuItemQuantity: (state, action) => {
      const { menuName, itemId } = action.payload;
      const menu = state.selectedMenus.find((m) => m.menuName === menuName);
    
      if (!menu) {
        console.error(`Menu "${menuName}" not found.`);
        return;
      }
      console.log("menu in slice increment", menu)

      const itemIndex = menu.selectedItems.findIndex((i) => i.sid === itemId);
      if (itemIndex !== -1) {
        const item = menu.selectedItems[itemIndex];
        item.quantity -= 1;
    
        if (item.quantity === 0) {
          menu.selectedItems.splice(itemIndex, 1); // Remove item if quantity is zero
        }
      } else {
        console.error(`Item with sid "${itemId}" not found in menu "${menuName}".`);
      }
    },
    // Remove item from menu
    removeItemFromMenu: (state, action) => {
      const { menuName, itemId } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        menu.items = menu.items.filter(i => i.sid !== itemId);
        menu.totalPrice = menu.items.reduce((total, item) => total + item.totalPrice, 0);
      }
    },
    setAnchorElId: (state, action) => {
      state.anchorElId = action.payload;
    },
    openPopover: (state, action) => {
      const { popoverID, anchorElId, foodItem } = action.payload || {};
      console.log("payload in open popover", action.payload)
      state.openPopover = !!popoverID;
      state.popoverID = popoverID || null;
      state.anchorElId = anchorElId || null;
      state.foodItemToAdd = foodItem || null;
      console.log("Popover opened with ID:", state.popoverID);
    },  
    closePopover: (state) => {
      state.openPopover = false;
      state.anchorElId = null;
      state.popoverID = null;
      state.menuName = null;
    },
    openMenuNameDialog: (state, action) => {
      const { foodItem, anchorElId, popoverID } = action.payload || {};
      console.log('openMenuNameDialog payload:', action.payload); // Log payload for debugging
      state.openMenuDialog = true;
      state.popoverID = popoverID; 
      state.anchorElId = anchorElId;
      state.menuName = action.payload.menuName || '';
      state.foodItemToAdd = foodItem;  
    },  
    closeMenuNameDialog: (state) => {
      state.openMenuDialog = false;
      state.anchorElId = null;
      state.popoverID = null;
      state.foodItemToAdd = null;
      state.menuName = null;
      // state.currentMenu = null;
      console.log("setting current menu to null in closing menu dialog..")
    },
    openConfirmDeleteDialog: (state, action) => {
      state.confirmDeleteDialog = true;
      state.deleteMenuName = action.payload;
    },
    closeConfirmDeleteDialog: (state) => {
      state.confirmDeleteDialog = false;
      state.deleteMenuName = null;
    },
    openItemDeleteDialog: (state, action) =>{
      state.openItemDelete = true;
      state.selectedItems = action.payload;
    },
    closeItemDeleteDialog: (state) => {
      state.openItemDelete = false;
      state.selectedItems = null;
    },
    setMenuName: (state, action) => {
      state.menuName = action.payload;
    },
    setDeleteMenuName: (state, action) => {
      state.deleteMenuName = action.payload;
    },
    setCurrentMenu: (state, action) => {
      state.currentMenu = action.payload;
    },
    setCurrentMenuIndex: (state, action) => {
      state.currentMenuIndex = action.payload;
    },
    createMenuFromItems: (state, action) => {
      const { menuName, selectedItems } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        menu.items = [...menu.items, ...selectedItems];
      }
    },
    setFoodItemToAdd: (state, action) => {
      state.foodItemToAdd = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedMenus: (state, action) => {
      state.selectedMenus = action.payload;
    },
    setJoinData: (state, action) => {
      state.joinData = action.payload;
    },
    fetchJoinDataRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchJoinDataSuccess: (state, action) => {
      state.joinData = action.payload;
      state.loading = false;
    },
    fetchJoinDataFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenus.pending, (state) => {
        console.log("menu fetched pending.");
        state.error = null;
        state.loading = false;
      })
      .addCase(fetchMenus.fulfilled, (state, action) => {
      state.loading = false;
      
        // Process the response data and map it to the menus and their selected items
        const menusData = action.payload;
        menusData.forEach(menu => {
          if (menu.menu_schedule) {
            try {
              const schedule = JSON.parse(menu.menu_schedule);
              menu.date = schedule.date;
              menu.time = schedule.time;
            } catch (e) {
              console.error('Error parsing menu_schedule', e);
            }
          }
        });
        // Select all menus
        state.menuId = menusData.map(menu => menu.menu_id);  // Store all menu_ids here
        const menus = menusData.reduce((acc, menu) => {
          // Ensure the menu exists in the accumulator
          if (!acc[menu.menu_id]) {
            const { date, time } = menu; 
            acc[menu.menu_id] = {
              menu_id: menu.menu_id,
              menuName: menu.menu_name,
              sid: menu.sid,
              selectedItems: [],
              total_price: parseFloat(menu.total_price) || 0,
              menu_schedule: { // Assign the separated date and time here
                date,
                time
              },
            };
          }
          console.log("menu date and time in reducers",acc[menu.menu_id].menu_schedule);
          acc[menu.menu_id].selectedItems.push({
            ...menu,  // Spread the full item object here
            quantity: menu.quantity, // Default quantity if needed
            item_total: parseFloat(menu.item_price) || 0, // Assuming price can be directly used for total price
          });
          return acc;
        }, {});
      
        // Convert the menus object back to an array
        state.selectedMenus = Object.values(menus);
      })      
      .addCase(fetchMenus.rejected, (state, action) => {
        console.log("menu fetched rejected");
        state.error = action.error.message || "Failed to fetch menus.";
        state.loading = false;
        state.selectedMenus = [];
      })
      .addCase(fetchMenusById.pending, (state) => {
        console.log("menu fetched pending.");
        state.error = null;
        state.loading = false;
      })
      .addCase(fetchMenusById.fulfilled, (state, action) => {
      state.loading = false;
      state.currentMenu = action.payload; // Set the fetched menu as the current menu
      console.log("Fetched menu by ID:", action.payload);
        // Process the response data and map it to the menus and their selected items
        const menusData = action.payload;
        menusData.forEach(menu => {
          if (menu.menu_schedule) {
            try {
              const schedule = JSON.parse(menu.menu_schedule);
              menu.date = schedule.date;
              menu.time = schedule.time;
            } catch (e) {
              console.error('Error parsing menu_schedule', e);
            }
          }
        });
        // Store the menu ID from the fetched menu
        state.menuId = menusData.menu_id;
        const menus = menusData.reduce((acc, menu) => {
          // Ensure the menu exists in the accumulator
          if (!acc[menu.menu_id]) {
            const { date, time } = menu; 
            acc[menu.menu_id] = {
              menu_id: menu.menu_id,
              menuName: menu.menu_name,
              sid: menu.sid,
              selectedItems: [],
              total_price: parseFloat(menu.total_price) || 0,
              menu_schedule: { // Assign the separated date and time here
                date,
                time
              },
            };
          }
          console.log("menu date and time in reducers of fetch menus by id",acc[menu.menu_id].menu_schedule);
          acc[menu.menu_id].selectedItems.push({
            ...menu,  // Spread the full item object here
            quantity: menu.quantity, // Default quantity if needed
            item_total: parseFloat(menu.item_price) || 0, // Assuming price can be directly used for total price
          });
          return acc;
        }, {});
      
        // Convert the menus object back to an array
        state.selectedMenus = Object.values(menus);
      })      
      .addCase(fetchMenusById.rejected, (state, action) => {
        console.log("menu fetched rejected");
        state.error = action.error.message || "Failed to fetch menus.";
        state.loading = false;
        state.selectedMenus = [];
      })
      //deleting menu extra menucers
      .addCase(deleteMenuFromServer.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMenuFromServer.fulfilled, (state, action) => {
        state.loading = false;
        // Remove the deleted menu from the selectedMenus array
        state.selectedMenus = state.selectedMenus.filter(
          (menu) => menu.menuName !== action.payload
        );
        if (state.currentMenu?.menuName === action.payload) {
          state.currentMenu = null;
        }
      })
      .addCase(deleteMenuFromServer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  addMenu,
  updateMenu,
  updateMenuDateTime,
  deleteMenu,
  addItemToMenu,
  removeItemFromMenu,
  incrementMenuItemQuantity,
  decrementMenuItemQuantity,
  openPopover,
  closePopover,
  openMenuNameDialog,
  closeMenuNameDialog,
  openConfirmDeleteDialog,
  closeConfirmDeleteDialog,
  openItemDeleteDialog,
  closeItemDeleteDialog,
  setMenuName,
  selectedMenus,
  setDeleteMenuName,
  setCurrentMenu,
  setCurrentMenuIndex,
  createMenuFromItems,
  setAnchorElId,
  setSelectedCategory,
  setFoodItemToAdd,
  setJoinData,
  fetchJoinDataRequest,
  fetchJoinDataSuccess,
  fetchJoinDataFailure,
} = menuSlice.actions;

// export const fetchMenus = menuSlice.actions.fetchMenus; // Include only if fetchMenus is explicitly defined.

export const joinDataSelector = (state) => state.menu.joinData;
export const fetchJoinData = () => async (dispatch) => {
  dispatch(fetchJoinDataRequest());
  try {
    const response = await axios.get(`${url}/joinData`);
    const data = response.data.data; // Adjust if needed
    if (Array.isArray(data)) {
      dispatch(fetchJoinDataSuccess(data));
    } else {
      dispatch(fetchJoinDataFailure("Data is not an array"));
    }
  } catch (error) {
    dispatch(fetchJoinDataFailure(error.message));
  }
};

export default menuSlice.reducer;
import React from "react";
import { Box, Typography } from "@mui/material";
import TrackSectionMap from "./track_section_map";
import TrackSectionOrderDetails from "./track_section_order_details";
import TrackDetail from './track_detail';
import "./track_section.css";
function TrackMainSection() {
  return (
    <Box>
      <Box>
        <Typography className="responsive_fontsize32"
          sx={{
            fontFamily: "Roboto Serif",
            // fontSize: "2rem",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Track Your Order
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "3rem",
          mt: "3.12rem",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { md: "row", xs: "column" },
        }}
      >
        <Box
          sx={{
            boxShadow: "1px 2px 4px 0px rgba(0, 0, 0, 0.25)",
            width: { md: "50%", xs: "90%" },
            padding: { xs: "0.5rem", sm: "1.8rem" },
          }}
        >
          <TrackSectionOrderDetails />
          <TrackDetail/>
        </Box>
        <Box sx={{ width: { md: "50%", xs: "90%" } }}>
          <TrackSectionMap />
        </Box>
      </Box>
    </Box>
  );
}

export default TrackMainSection;

import React from 'react';
import { Grid, Box } from '@mui/material';
import FoodCard from '../FoodCard/FoodCard';

function GridLayout({ searchedItems }) {
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {searchedItems.map((fooditem) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={fooditem.item_id}>
          <Box sx={{ position: "relative" }}>
            <FoodCard
              fooditem={fooditem}
              subcategory={`${fooditem.item_subcategory} ${fooditem.item_type} ${fooditem.item_name}`}
              price={fooditem.item_price}
              image={fooditem.item_image}
              
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

export default GridLayout;

// redux/slices/subscriptionSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "https://www.bbgrestaurant.com/bbgapi";

// Thunk to fetch subscriptions
export const fetchSubscriptions = createAsyncThunk(
  "subscription/fetchSubscriptions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/subscription`);
      const nestedData = response.data.data;
      
      // Check if nestedData[0] exists and is an array
      if (!Array.isArray(nestedData) || !Array.isArray(nestedData[0])) {
        throw new Error("Unexpected data format from API");
      }

      const subscriptions = nestedData[0]; // Extract the required array
      
      // Validate and format subscriptions
      const validSubscriptions = subscriptions.filter(
        (sub) =>
          sub.subscription_id &&
          typeof sub.subscription_type === "string" &&
          typeof sub.discount === "string"
      );

      const formattedSubscriptions = validSubscriptions.map((sub) => {
        if (sub.subscription_package) {
          try {
            sub.subscription_package = JSON.parse(sub.subscription_package);
          } catch {
            console.error(
              `Failed to parse subscription_package: ${sub.subscription_package}`
            );
          }
        }
        return sub;
      });

      console.log("Filtered and formatted subscriptions:", formattedSubscriptions);
      return formattedSubscriptions;
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      return rejectWithValue(
        error.response ? error.response.data : "Error fetching subscriptions"
      );
    }
  }
);


// Redux Slice
const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    subscriptions: [], // Will store normalized subscriptions
    loading: false,
    error: null,
  },
  reducers: {
    clearSubscriptionStatus(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Subscriptions
      .addCase(fetchSubscriptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.loading = false;
        // Flatten the data structure if there are nested arrays
        state.subscriptions = action.payload.flat();
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearSubscriptionStatus } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;

import React from "react";
import { Box, Button, Grid,Typography } from "@mui/material";
import esewa from "./payment_assets/esewa.png";
import khalti from "./payment_assets/khalti_logo.png";
import fonepay from "./payment_assets/fonepay.png";
import mobileBanking from "./payment_assets/mob_banking.png";

function PaymentMethodSection() {
  return (
    <Box sx={{ width: "100%", py: "1rem", }}>
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "50px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#f1f1f1",
              },
            }}
          >
            <Typography>Cash On Delivery</Typography>
          </Button>
        </Grid>

        {/* eSewa */}
        <Grid item xs={12} sm={6} md={3}>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "50px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#f1f1f1",
              },
            }}
          >
            <img
              src={esewa}
              alt="eSewa"
              style={{
                height: "28px",
                width: "55px",
                objectFit: "contain",
              }}
            />
          </Button>
        </Grid>

        {/* Khalti */}
        <Grid item xs={12} sm={6} md={3}>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "50px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#f1f1f1",
              },
            }}
          >
            <img
              src={khalti}
              alt="Khalti"
              style={{
                height: "22px",
                width: "63px",
                objectFit: "contain",
              }}
            />
          </Button>
        </Grid>

        {/* FonePay */}
        <Grid item xs={12} sm={6} md={3}>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "50px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#f1f1f1",
              },
            }}
          >
            <img
              src={fonepay}
              alt="FonePay"
              style={{
                height: "35px",
                width: "90px",
                objectFit: "contain",
              }}
            />
          </Button>
        </Grid>

        {/* Mobile Banking */}
        <Grid item xs={12} sm={6} md={3}>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "50px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#f1f1f1",
              },
            }}
          >
            <img
              src={mobileBanking}
              alt="Mobile Banking"
              style={{
                height: "13px",
                width: "88px",
                objectFit: "contain",
              }}
            />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PaymentMethodSection;

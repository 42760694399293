import { Typography, Box, Grid } from "@mui/material";
import React, { useState } from "react";
import FoodCard from "src/views/menu/components/FoodCard/FoodCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";

function HomeThirdSection() {
  const [sliderIndex, setSliderIndex] = useState(0);
  const joinData = useSelector((state) => state.menus.joinData);
  
  // const totalSlides = joinData[0]?.length || 0;
  // const maxDotsToShow = 5; // Limit to 5 visible dots

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    beforeChange: (current, next) => setSliderIndex(next),
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: (i) => {
      return (
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            background: i === sliderIndex ? "#656566" : "lightgray",
            display: "inline-block",
            margin: "0 5px",
          }}
        />
      );
    },
    appendDots: (dots) => {
      // Limit the number of dots to a maximum of 5
      const maxDotsToShow = 5;
      const limitedDots = dots.slice(0, maxDotsToShow);
      return (
        <ul style={{ display: "flex", justifyContent: "center", padding: 0 }}>
          {limitedDots}
        </ul>
      );
    },
  };
  return (
    <Box>
      <Box>
        <Typography className="responsive_fontsize32"
          sx={{
            fontFamily: "Roboto Serif",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Popular
        </Typography>
      </Box>
      <Box sx={{ mt: "3.5rem 2rem" }} className="slick__popular">
        <Slider {...settings}>
          {joinData[0]?.map((fooditem, index) => (
            <Grid item key={`${fooditem.item_id}-${index}`}>
              <Box sx={{ position: "relative" }}>
                <FoodCard
                  fooditem={fooditem}
                  subcategory={`${fooditem.item_subcategory} ${fooditem.item_type} ${fooditem.item_name}`} 
                  price={fooditem.item_price}
                  image={fooditem.item_image}
                />
              </Box>
            </Grid>
          ))}
        </Slider>
      </Box>
    </Box>
  );
}

export default HomeThirdSection;

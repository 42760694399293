import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import FoodCard from "src/views/menu/components/FoodCard/FoodCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ItemList = ({ displayedItems, isMobile, sliderSettings }) => (
  <>
    {isMobile ? (
      <Box
        sx={{
          width: "100%",
          "& .slick-slide": {
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        <Slider {...sliderSettings}>
          {displayedItems.map((fooditem) => (
            <Box key={fooditem.item_id} sx={{ position: "relative" }}>
              <FoodCard
                fooditem={fooditem}
                subcategory={`${fooditem.item_subcategory} ${fooditem.item_type} 
                ${fooditem.item_name}`}
                price={fooditem.item_price}
                image={fooditem.item_image}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    ) : (
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        {displayedItems.length > 0 ? (
          displayedItems.map((fooditem) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={fooditem.item_id}>
              <Box sx={{ position: "relative" }}>
                <FoodCard
                  fooditem={fooditem}
                  subcategory={`${fooditem.item_subcategory} ${fooditem.item_type} ${fooditem.item_name}`}
                  price={fooditem.item_price}
                  image={fooditem.item_image}
                />
              </Box>
            </Grid>
          ))
        ) : (
          <Typography variant="subtitle1" color="textSecondary">
            No veg items available.
          </Typography>
        )}
      </Grid>
    )}
  </>
);

export default ItemList;
